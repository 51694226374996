import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import eventToAccounts from "./eventToAccounts.json";
import store from "store";
import { Ledgers } from "reducers/typesSchema/allAccountLedgersSchema";
import { upsertAllAccountLedgers } from "actions/allAccountLedgersActions";

interface AggregationQuery {
    Query: {
        Type: string;
        SubTypes: string[];
        Ids: string[];
        Filters: { [key: string]: any };
    };
    ContextKey: string;
    SaveTo: string;
    Collate: boolean;
    KeepLatest: boolean;
    Multiplier?: number;
}

export interface Ledger {
    Name: string;
    Id: string;
    LedgerQueries: AggregationQuery[];
    LedgerType?: string;
}

// Add a new ledger to the ledgerMap
export const addRelevantLedger = (
    entity: EntitySchema,
    ledgerMap: { [key: string]: Ledger }
) => {
    const accountsAndLedgers =
        store.getState().allAccountLedgers.ledgersMetadata;

    const accounts = getRelevantAccount(entity, accountsAndLedgers);
    if (accounts.length === 0) return ledgerMap;
    accounts.forEach((account) => {
        addToLedgerMap(ledgerMap, account, accountsAndLedgers);
    });
    // Hard-coded temporary fix for calculated accounts
    addCalculatedAccounts(ledgerMap, accountsAndLedgers);
    return ledgerMap;
};

const getRelevantAccount = (
    entity: EntitySchema,
    accountsAndLedgers: Ledgers
): { Id: string; Name: string }[] => {
    const accounts: { Id: string; Name: string }[] = [];
    if (eventToAccounts[entity.type]) {
        accounts.push(...eventToAccounts[entity.type].ledgers);
    }

    // Add any dependant account that the event requires (ie. A revenue account for the loan event)
    if (entity.data.dependantAccountId && entity.data.dependantAccount) {
        accounts.push({
            Name: entity.data.dependantAccount,
            Id: entity.data.dependantAccountId,
        });
    }

    // Add all contra accounts that the event requires
    if (
        entity.data.contraAccountIds &&
        entity.data.contraAccountIds.length > 0
    ) {
        entity.data.contraAccountIds.forEach((accountId) => {
            const account = accountsAndLedgers[accountId];
            if (account) {
                accounts.push({ Name: account.name, Id: account.id });
            }
        });
    }

    // Add all main ledger accounts that the event requires
    if (entity.data.accountIds && entity.data.accountIds.length > 0) {
        entity.data.accountIds.forEach((accountId) => {
            const account = accountsAndLedgers[accountId];
            if (account) {
                accounts.push({ Name: account.name, Id: account.id });
            }
        });
    }

    return accounts;
};

const addToLedgerMap = (
    ledgerMap: { [key: string]: Ledger },
    account: { Id: string; Name: string },
    accountsAndLedgers: Ledgers
) => {
    const accountName: string = account.Name;
    const accountUUID: string = account.Id;
    const ledger = accountsAndLedgers[accountUUID];
    if (ledger.parents.length >= 1) {
        ledger.parents.forEach((parentUUID) => {
            const parentLedger = accountsAndLedgers[parentUUID];
            addToLedgerMap(
                ledgerMap,
                {
                    Id: parentLedger.id,
                    Name: parentLedger.name,
                },
                accountsAndLedgers
            );
        });
    }
    if (!ledgerMap[accountUUID]) {
        ledgerMap[accountUUID] = createLedger(
            accountName,
            accountUUID,
            false,
            accountsAndLedgers
        );
        ledgerMap["Cumulative " + accountUUID] = createLedger(
            "Cumulative " + accountName,
            accountUUID,
            true,
            accountsAndLedgers
        );
    }
};

const createLedger = (
    name: string,
    type: string,
    isCumulative: boolean,
    accountsAndLedgers: Ledgers
): Ledger => {
    const filters = {};
    addParentFilters(filters, type, accountsAndLedgers);
    addChildrenFilters(filters, type, accountsAndLedgers);
    const topLevelAccountUUID: string = getTopLevelAccount(
        type,
        accountsAndLedgers
    );
    return {
        Name: name,
        Id: isCumulative ? `Cumulative-${type}` : type,
        LedgerQueries: [
            {
                Query: {
                    Type: topLevelAccountUUID,
                    SubTypes: [],
                    Ids: [],
                    Filters: filters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: isCumulative,
            },
        ],
        LedgerType: isCumulative ? `Cumulative-${type}` : type,
    };
};

// Recursively work up the account/ledger tree, adding parents along the way
const addParentFilters = (filters, ledgerUUID, accountsAndLedgers: Ledgers) => {
    const ledger = accountsAndLedgers[ledgerUUID];
    if (ledger.parents.length >= 1) {
        ledger.parents.forEach((parentUUID) => {
            addParentFilters(filters, parentUUID, accountsAndLedgers);
            filters["__" + ledger.id] = true;
        });
    }
    return filters;
};

// Add all children of the current account/ledger (non-recursive)
const addChildrenFilters = (
    filters,
    ledgerUUID,
    accountsAndLedgers: Ledgers
) => {
    const ledger = accountsAndLedgers[ledgerUUID];
    if (ledger.children.length > 0) {
        ledger.children.forEach((childUUID) => {
            filters["__" + childUUID] = null;
        });
    }
    return filters;
};

const getTopLevelAccount = (uuid, accountsAndLedgers: Ledgers) => {
    let ledger = accountsAndLedgers[uuid];
    while (ledger.parents.length >= 1) {
        ledger = accountsAndLedgers[ledger.parents[0]];
    }
    return ledger.id;
};

// Literally just manually added each of the default calculated accounts to the ledgerMap lol
// TODO: Automate the addition of calculated accounts, and modify accountAndLedger structure to
//       support one way relationships (ie. Cashflow has Assets as parent, but is not child of Assets)
const addCalculatedAccounts = (
    legderMap: { [key: string]: Ledger },
    accountsAndLedgers: Ledgers
) => {
    const assetsFilters = {};
    addChildrenFilters(
        assetsFilters,
        "211dd944-359f-4fbe-af1f-0c761afa1e67",
        accountsAndLedgers
    );
    const liabilitiesFilters = {};
    addChildrenFilters(
        liabilitiesFilters,
        "6c9640aa-abf5-4f19-b135-356e183bcce6",
        accountsAndLedgers
    );
    const incomeFilters = {};
    addChildrenFilters(
        incomeFilters,
        "488dd61d-8697-4213-8978-cf91755365a4",
        accountsAndLedgers
    );
    const expensesFilters = {};
    addChildrenFilters(
        expensesFilters,
        "7faf0285-78ca-411b-b875-d900929d7c94",
        accountsAndLedgers
    );

    // Cashflow account uses the same filters as Cash account
    const cashflowFilters = {
        "__61f75c52-c089-4137-b424-e2f7bfc4d340": true,
        "__507f8682-dd54-477f-b35d-9062af491587": null,
        "__76919215-1601-4634-811f-54d3af8b8fa9": true,
        "__dcd7c099-1d0f-40f1-a265-6d30a35a6098": null,
    };

    legderMap["Cashflow"] = {
        Name: "Cashflow",
        Id: "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
        LedgerQueries: [
            {
                Query: {
                    Type: "211dd944-359f-4fbe-af1f-0c761afa1e67",
                    SubTypes: [],
                    Ids: [],
                    Filters: cashflowFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: false,
            },
        ],
        LedgerType: "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
    };
    legderMap["Cumulative Cashflow"] = {
        Name: "Cumulative Cashflow",
        Id: "Cumulative-1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
        LedgerQueries: [
            {
                Query: {
                    Type: "211dd944-359f-4fbe-af1f-0c761afa1e67",
                    SubTypes: [],
                    Ids: [],
                    Filters: cashflowFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: true,
            },
        ],
        LedgerType: "Cumulative-1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
    };
    legderMap["Calculated Equity"] = {
        Name: "Calculated Equity",
        Id: "a4d432a8-26ab-41f0-9008-88ae2d171378",
        LedgerQueries: [
            {
                Query: {
                    Type: "211dd944-359f-4fbe-af1f-0c761afa1e67",
                    SubTypes: [],
                    Ids: [],
                    Filters: assetsFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: false,
            },
            {
                Query: {
                    Type: "6c9640aa-abf5-4f19-b135-356e183bcce6",
                    SubTypes: [],
                    Ids: [],
                    Filters: liabilitiesFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: false,
                // Only known use of multiplier -> we want the inverse of the liabilities values
                Multiplier: -1,
            },
        ],
        LedgerType: "a4d432a8-26ab-41f0-9008-88ae2d171378",
    };
    legderMap["Cumulative Calculated Equity"] = {
        Name: "Cumulative Calculated Equity",
        Id: "Cumulative-a4d432a8-26ab-41f0-9008-88ae2d171378",
        LedgerQueries: [
            {
                Query: {
                    Type: "211dd944-359f-4fbe-af1f-0c761afa1e67",
                    SubTypes: [],
                    Ids: [],
                    Filters: assetsFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: true,
            },
            {
                Query: {
                    Type: "6c9640aa-abf5-4f19-b135-356e183bcce6",
                    SubTypes: [],
                    Ids: [],
                    Filters: liabilitiesFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: true,
                Multiplier: -1,
            },
        ],
        LedgerType: "Cumulative-a4d432a8-26ab-41f0-9008-88ae2d171378",
    };
    legderMap["Profit"] = {
        Name: "Profit",
        Id: "4db53c56-db20-4798-9830-adbfcc977b26",
        LedgerQueries: [
            {
                Query: {
                    Type: "488dd61d-8697-4213-8978-cf91755365a4",
                    SubTypes: [],
                    Ids: [],
                    Filters: incomeFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: false,
            },
            {
                Query: {
                    Type: "7faf0285-78ca-411b-b875-d900929d7c94",
                    SubTypes: [],
                    Ids: [],
                    Filters: expensesFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: false,
                // Second known use of multiplier -> we want the inverse of the expenses values
                Multiplier: -1,
            },
        ],
        LedgerType: "4db53c56-db20-4798-9830-adbfcc977b26",
    };
    legderMap["Cumulative Profit"] = {
        Name: "Cumulative Profit",
        Id: "Cumulative-4db53c56-db20-4798-9830-adbfcc977b26",
        LedgerQueries: [
            {
                Query: {
                    Type: "488dd61d-8697-4213-8978-cf91755365a4",
                    SubTypes: [],
                    Ids: [],
                    Filters: incomeFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: true,
            },
            {
                Query: {
                    Type: "7faf0285-78ca-411b-b875-d900929d7c94",
                    SubTypes: [],
                    Ids: [],
                    Filters: expensesFilters,
                },
                ContextKey: "value",
                SaveTo: "value",
                Collate: false,
                KeepLatest: true,
                Multiplier: -1,
            },
        ],
        LedgerType: "Cumulative-4db53c56-db20-4798-9830-adbfcc977b26",
    };
};

// TODO: move this logic into backend
export const correctLedgersAndUpsert = (ledgerMap: Ledgers) => {
    const accountsAndLedgers =
        store.getState().allAccountLedgers?.ledgersMetadata || {};
    Object.entries(accountsAndLedgers).forEach(([id, ledger]) => {
        if (!ledgerMap[id]) {
            ledger.type = "shared";
            ledgerMap[id] = ledger;
        }
    });

    Object.values(ledgerMap).forEach((ledger) => {
        const originalLedgerId = ledger.id;
        ledger.parents.forEach((parentUUID) => {
            const parentLedger = ledgerMap[parentUUID];
            if (!parentLedger.children.includes(originalLedgerId)) {
                parentLedger.children.push(originalLedgerId);
            }
        });
    });

    store.dispatch(upsertAllAccountLedgers({ ledgersMetadata: ledgerMap }));
};
