// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    RequiredStar,
    ModalRowHalf,
    Rate,
    MuiCalendar,
    BasicHeading,
    RadioButton,
    ModalRowOneThird,
    EntityCard,
} from "../Components";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import { capacityObject } from "Components/Registry/Capacity";

export default function CapacityInputView({
    entitiesMap,
    currentEntity,
    eventData,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    updateAccount,
    handleChangeWorkDays,
    handleDateSelection,
}) {
    const entityData = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={capacityObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={capacityObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[capacityObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow></ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap?.[currentEntity]?.data
                                    ?.accountName ?? ""
                            }
                            topLevelFilters={{
                                "e18faebd-5cb1-40ac-8c18-442943677d4a": true,
                            }}
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap?.[currentEntity]?.data
                                    ?.contraAccountName ?? ""
                            }
                            topLevelFilters={{
                                "3ebf3eb4-6cdf-4889-8796-435cb9bf0939": true,
                            }}
                            contraAccount={true}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <BasicHeading copy={"Staff to a Set Percentage of Need"} />
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Rate
                            id="value"
                            onChangeInput={handleOnChange}
                            value={entityData?.value}
                            inputAdornmentOverride="%"
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <ModalRowOneThird>
                        <RadioButton
                            id="workDays"
                            onClick={handleChangeWorkDays}
                            value="5"
                            isActive={entityData?.workDays === 5}
                            label="5 day weeks"
                        />
                    </ModalRowOneThird>
                    <ModalRowOneThird>
                        <RadioButton
                            id="workDays"
                            onClick={handleChangeWorkDays}
                            value="6"
                            isActive={entityData?.workDays === 6}
                            label="6 day weeks"
                        />
                    </ModalRowOneThird>
                    <ModalRowOneThird>
                        <RadioButton
                            id="workDays"
                            onClick={handleChangeWorkDays}
                            value="7"
                            isActive={entityData?.workDays === 7}
                            label="7 day weeks"
                        />
                    </ModalRowOneThird>
                </ModalRow>
                <ModalRow></ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={
                                entitiesMap?.[currentEntity]?.startDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.startDate
                            }
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="endDate"
                            value={
                                entitiesMap?.[currentEntity]?.endDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.endDate
                            }
                            onChangeInput={handleDateSelection}
                            label="End Date"
                            helperText="An end date is optional"
                        />
                    </ModalRowHalf>
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}
