import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent, MutableRefObject } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { getPresentableDependenciesOfManyTypes } from "helpers/nodeDependencyDetectionHelpers";
import { resourceObject } from "Components/Registry/Resource";
import { resourceInputsHandler } from "../OnInputChangeHandlers/resourceInputsHandler";
import ResourceInputView from "./ResourceInputView";
import moment from "moment";
import { getRelevantEntities } from "actions/getNodeEntityActions";

export default function ResourceInput({ line, edit, editData, focus }) {
    const dispatch = useAppDispatch();

    const loadedScenario: MutableRefObject<ScenarioSchema> = useRef({
        ...useAppSelector((state) => state?.scenario?.loadedScenario),
    });

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...loadedScenario.current?.dependency_map,
    });

    const eventId = useContext(EventInputIDContext);

    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = getDefaultName(resourceObject.name(), propsObject);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(resourceObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIds,
        entityIndex,
        setEntitiesMap,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        resourceObject,
        dependencyMap.current
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "cadence"
            | "entityName"
            | "startDate"
            | "endDate"
            | "entity"
            | "resourceCadence"
            | "resourceDuration"
            | "accountName"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = resourceInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = resourceInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const provideInheritedDate = (startOrEnd) => {
        const currentEntityData: any = entitiesMap?.[currentEntity];
        const selectedEventEntity = Object.values(
            getRelevantEntities(currentEntityData?.data?.selectedIds) ?? {}
        )?.[0];

        if (selectedEventEntity) {
            return selectedEventEntity?.[startOrEnd];
        }

        return null;
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = resourceInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap?.current,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const toggleInheritDate = (checked, key: string) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data[key] = checked;

        if (checked) data.setOrDistributed = "set";

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    const resetData = (key: string, data, currentEntityObject) => {
        if (key === "fixedOrAmortized") {
            currentEntityObject.endDate = "";
            data.resourceDuration = 0;
            data.distribution = [];
            return;
        }
    };

    const handleChangeFlags = (
        e: ChangeEvent<HTMLInputElement>,
        key: string
    ) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        resetData(key, data, currentEntityObject);

        data[key] = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const getEntitySummary = () => {
        return "Placeholder.";
    };

    const getValidEvents = () => {
        const events = {};
        const validEventType: string[] = [
            "00dd7b5a-c2f5-4e27-86eb-c744bb93c5a3", // Contract
            "80f9a89f-9103-41a0-bbe4-b5952cc488bf", // Income
        ];

        getPresentableDependenciesOfManyTypes(
            events,
            validEventType,
            propsObject,
            "Target",
            true
        );

        return events;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const currentEntityObject = {
                ...(newEntitiesMap[currentEntity] || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            data.events = getValidEvents();
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            return newEntitiesMap;
        });
        // setEntitiesMap should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = resourceObject.checkInput(entitiesMap);

    const getMonthsDifference = () => {
        const start = moment(entitiesMap[currentEntity].startDate);
        const end = moment(entitiesMap[currentEntity].endDate);

        if (!start.isValid() || !end.isValid()) return 0;
        return end.diff(start, "M") + 1;
    };

    const getMonthsArray = () => {
        const numberOfMonths = getMonthsDifference();
        const start = moment(entitiesMap[currentEntity].startDate);

        const monthStringsArray = [start.format("MMM YY")];

        let current = 1;

        while (current < numberOfMonths) {
            const startDateObject = moment(
                entitiesMap[currentEntity].startDate
            );
            const newMonthDateString = startDateObject
                .add(current, "M")
                .format("MMM YY");
            monthStringsArray.push(newMonthDateString);
            current++;
        }

        return monthStringsArray;
    };

    const addUpdateDistribution = (distData: number[]) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        let sum = 0;
        for (const data of distData) {
            sum += data;
        }

        const normalizedData = distData.map((data) => data / sum);

        data.nnDistribution = distData;
        data.distribution = normalizedData;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <ResourceInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            handleDateSelection={handleDateSelection}
            updateAccount={updateAccount}
            handleChangeFlags={handleChangeFlags}
            toggleInheritDate={toggleInheritDate}
            getEntitySummary={getEntitySummary}
            getMonthsDifference={getMonthsDifference}
            getMonthsArray={getMonthsArray}
            addUpdateDistribution={addUpdateDistribution}
            provideInheritedDate={provideInheritedDate}
        />
    );
}
