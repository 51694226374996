import React from "react";
import closeSvg from "../../Assets/close.svg";
import "./NodeMenu.css";
import BudgetCard from "../Card/budgetCard";
import editSvg from "../../Assets/_nodeIcons/edit.png";
// import PinEvent from "../../Assets/_nodeIcons/pin-no-bg.svg";
import deleteSvg from "../../Assets/_nodeIcons/delete.png";
import bypassSvg from "../../Assets/_canvasIcons/bypass-black.png";
import lockSvg from "../../Assets/_canvasIcons/lock-black.png";
import ScenarioComment from "../UserScenarios/comment";
import IncomeTaxCard from "../Card/incomeTaxCard";
import MeCard from "../Card/meCard";
import StartCard from "../Card/startCard";
import { EventsPopUp } from "../EventsPopUp";
import personBlack from "../../Assets/_eventNodeTypes/person-black.png";
import addOptionSvg from "../../Assets/_baseline/addOption.png";
import {
    createBaselineScenario,
    addNodeToBaseline,
    addNodeToNewPerson,
} from "../../helpers/baselineFunctions";

import { throwError } from "../../helpers/swalError";
import CreateContainerNodeMenu from "./partials/CreateContainerNodeMenu";
import { Student_IncomeObject } from "../Registry/Student Income";
import { rentalIncomeObject } from "../Registry/Rental Income";
import { expenseObject } from "../Registry/Expense";
import { tuitionObject } from "../Registry/Tuition";
import { budgetObject } from "../Registry/Budget";
import { studentBudgetObject } from "../Registry/Student Budget";
import { retirementObject } from "../Registry/Retirement";
import { graduationObject } from "../Registry/Graduation";
import { loanObject } from "../Registry/Loan";
import { studentLoanObject } from "../Registry/Student Loan";
import { debtObject } from "../Registry/Debt";
import { debtRepaymentObject } from "../Registry/Debt Repayment";
import { customerObject } from "../Registry/Customer";
import { grantObject } from "../Registry/Grant";
import { customerGrowthObject } from "../Registry/CustomerGrowth";
import { customerChurnObject } from "../Registry/CustomerChurn";
import { unitCostObject } from "../Registry/Unit Cost";
import { revenueObject } from "../Registry/Revenue";
import { bankObject } from "../Registry/Bank";
import { bankPlaidObject } from "../Registry/BankPlaid";
import { bankAltObject } from "../Registry/BankAlt";
import { incomeObject } from "../Registry/Income";
import { houseObject } from "../Registry/House";
import { rentObject } from "../Registry/Rent";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
import { rrspObject } from "Components/Registry/RRSP";
import { investmentObject } from "Components/Registry/Investment";
import { mortgageObject } from "Components/Registry/Mortgage";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
import { propertyValueObject } from "Components/Registry/Property Value";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { employeeObject } from "Components/Registry/Employee";
import { capitalGainsObject } from "Components/Registry/Capital Gains";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { pensionObject } from "Components/Registry/Pension";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { meObject } from "Components/Registry/Me";
import { startObject } from "Components/Registry/Start";
import { goalObject } from "Components/Registry/Goal";
import { modifierObject } from "Components/Registry/Modifier";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import { equityObject } from "Components/Registry/Equity";
import { maternityObject } from "Components/Registry/Maternity";
import { condoFeeObject } from "../Registry/CondoFee";
import { cacObject } from "Components/Registry/CAC";
import { contractObject } from "Components/Registry/Contract";
import * as uuid from "uuid";
import { nullObject } from "Components/Registry/Null";
import { businessObject } from "Components/Registry/Business";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { kpiObject } from "Components/Registry/KPI";
import { groupObject } from "Components/Registry/Group";
import { EventActionIds, eventActionsMap } from "actions/canvasEventActions";
import { instanceObject } from "Components/Registry/Instance";
import store from "store";
import { modifier2Object } from "Components/Registry/Modifier2";
import { projectObject } from "Components/Registry/Project";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { expressionObject } from "Components/Registry/Expression";
import { importObject } from "Components/Registry/Import";
import { accountImportObject } from "Components/Registry/AccountImport";
import { growthObject } from "Components/Registry/Growth";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { customer2Object } from "Components/Registry/Customer2";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { CAC2Object } from "Components/Registry/CAC2";
import { allocationObject } from "Components/Registry/Allocation";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { percentageObject } from "Components/Registry/Percentage";
import { containerObject } from "Components/Registry/Container";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { unitObject } from "Components/Registry/Unit";
import { projectsObject } from "Components/Registry/Projects";
import { utilizationObject } from "Components/Registry/Utilization";

const SINGLE_NODE_GRAPH_EXPERIMENTAL = [
    "dev.whatifi.io",
    "localhost",
    "127.0.0.1",
].includes(window.location.hostname);

const CONTAINER_EXPERIMENTAL = [
    "dev.whatifi.io",
    "localhost",
    "127.0.0.1",
].includes(window.location.hostname);

class NodeMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inflation: null,
            cardState: "info",
            showBaselinePopUp: false,
            baselineNode: null,
            baselineNodeSelected: null,
            noBaselineNodeSelected: "meNode",
        };
    }

    componentDidMount() {
        const { loadedScenario } = this.props;
        if (loadedScenario) {
            const inflation = parseFloat(loadedScenario.inflation) * 100;
            this.setState({ inflation });
        }

        if (this.props.baselineDataManager.hasActiveBaseline()) {
            const activeBaseline =
                this.props.baselineDataManager.getActiveBaseline();
            const personNodes = activeBaseline.data.nodes.filter((node) => {
                return node.type === "Me" || node.type === "Person";
            });
            this.setState({
                baseline: activeBaseline,
                baselineNode: activeBaseline.data.nodes,
                baselineNodeSelected: personNodes[0],
            });
        }
    }

    closeMenu = () => {
        this.props.resetFocus();
    };

    onHandleClick = (actionId) => {
        store.dispatch(
            eventActionsMap[actionId](
                this.props.focus.id,
                this.props.inBaseline
            )
        );
        store.dispatch(eventActionsMap[EventActionIds.focusEvent](null));
        store.dispatch(
            eventActionsMap[EventActionIds.focusEvent](
                this.props.focus.id,
                this.props.inBaseline
            )
        );
    };

    onHandleCardState = (cardState) => {
        this.setState({ cardState });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.loadedScenario !== this.props.loadedScenario) {
            this.setState({});
        }

        if (prevProps.reload !== this.props.reload) {
            this.setState({});
        }

        if (prevProps.focus !== this.props.focus) {
            // console.log(this.props.focus.metadata.excludedBaselineNode);
            // console.log("different");
        }
    }

    handleAddToBaselinePopUp = () => {
        //pop up to confirm
        if (this.props.loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }
        if (this.props.focus.type === "Me") {
            this.handleViewBaseline();
        } else {
            //if there is no baseline create one
            this.setState({ showBaselinePopUp: true });
        }
    };

    handleAddToLibrary = () => {
        const {
            focus,
            loadedScenario,
            eventLibrary,
            manager,
            baselineDataManager,
            updateNode,
            addLibraryEvents,
        } = this.props;
        if (loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }
        const updatedNode = {
            ...focus,
            isLibraryEvent: true,
        };
        if (!updatedNode.libraryEventId) updatedNode.libraryEventId = uuid.v4();
        updateNode(
            updatedNode,
            loadedScenario,
            manager,
            { id: focus.id },
            baselineDataManager
        );
        addLibraryEvents([updatedNode], focus.type, eventLibrary);
    };

    handleCreateContainer = () => {
        this.props.upsertContainerCreation({
            isCreatingContainer: true,
            prospectiveHeadNode: this.props.focus,
        });
    };

    handleAddToBaseline = () => {
        // This is the function that adds normal node to baseline node
        const { baselineManager, focus, baselineDataManager } = this.props;
        const { baselineNodeSelected, noBaselineNodeSelected } = this.state;
        if (!baselineDataManager.hasActiveBaseline()) {
            throwError(
                "error",
                "No Baseline",
                "Please create a Baseline in the Baseline Canvas before adding any events."
            );
            return;
        }
        const baseline = baselineDataManager.getActiveEvents();
        if (baseline) {
            const activeBaselineData = baselineDataManager.hasActiveBaseline()
                ? baselineDataManager.getActiveBaseline(true).data
                : null;
            baselineManager.importData(activeBaselineData);
            //import baseline to the manager
            if (
                baselineNodeSelected.id === "newPerson" ||
                noBaselineNodeSelected === "newPerson"
            ) {
                addNodeToNewPerson(baselineManager, focus);
            } else {
                let parentNode = baselineNodeSelected;
                let node = baselineManager._findEvent(parentNode.id);
                if (baselineNodeSelected.type === "Baseline") {
                    addNodeToBaseline(focus, node, baselineManager);
                } else {
                    while (node && node.children.length > 0) {
                        node = baselineManager._findEvent(node.children[0].id);
                    }
                    addNodeToBaseline(focus, node, baselineManager);
                }
            }
        } else {
            // when there is no baseline
            const loggedInUser = JSON.parse(
                localStorage.getItem("loggedInUser")
            );
            this.props.getUserData(loggedInUser).then((data) => {
                return data;
            });
            if (this.props.baselineManager) {
                createBaselineScenario(
                    this.props.baselineManager,
                    this.props.createBaseline,
                    this.props.loadScenario,
                    focus,
                    noBaselineNodeSelected,
                    baselineDataManager
                );
            }
        }

        const toastData = {
            type: "success",
            text: `Successfully add "${focus.name}" to My Baseline `,
            upgrade: false,
            route: "/baseline",
        };
        let newBaseline = {
            ...baselineDataManager.getActiveBaseline(),
        };
        newBaseline.data = {
            ...newBaseline.data,
            ...this.props.baselineManager.exportData(),
        };

        this.props.updateBaseline(newBaseline, baselineDataManager);
        this.props.onHandleEventPasteToast(toastData);
        focus.isMovedToBaselineToggle();
        focus.toggleBaseline();
        baselineManager.calculate();
        this.setState({ showBaselinePopUp: false });
        // baselineManager._updateScenarioCanvas();
        // this.props.manager.importBaseline(baselineManager.exportData())
        // this.props.manager._updateScenarioCanvas()
    };

    updateEvents = () => {
        this.props.manager.calculate();
    };

    handleViewBaseline = () => {
        this.props.history.push("/baseline");
    };

    cancelAddToBaseline = () => {
        this.setState({ showBaselinePopUp: false });
    };

    handleChangeSelected = (node) => {
        if (node.id === "newPerson") {
            this.setState({ noBaselineNodeSelected: "newPerson" });
        }
        this.setState({ baselineNodeSelected: node });
    };

    handleBaselineSelected = (id) => {
        this.setState({ noBaselineNodeSelected: id });
    };

    render() {
        const {
            focus,
            // baselineDataManager,
            inBaseline,
            threadsAlias,
        } = this.props;
        // const baseline = baselineDataManager.getActiveBaseline();
        const {
            cardState,
            showBaselinePopUp,
            baselineNode,
            baselineNodeSelected,
            noBaselineNodeSelected,
        } = this.state;

        let actionsArray = [];

        if (CONTAINER_EXPERIMENTAL) {
            const { containerCreation } = this.props;
            if (containerCreation.isCreatingContainer) {
                const { prospectiveHeadNode, prospectiveTailNode } =
                    containerCreation;

                return (
                    <CreateContainerNodeMenu
                        prospectiveHeadNode={prospectiveHeadNode}
                        prospectiveTailNode={prospectiveTailNode}
                        resetContainerCreation={
                            this.props.resetContainerCreation
                        }
                        manager={this.props.manager}
                    />
                );
            }
        }

        if (SINGLE_NODE_GRAPH_EXPERIMENTAL) {
            actionsArray = [
                {
                    name: EventActionIds?.editEvent,
                    image: editSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.addEventForDeletion,
                    image: deleteSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.bypassEvent,
                    image: bypassSvg,
                    active: focus.isBypassed(),
                },
                {
                    name: EventActionIds?.lockEvent,
                    image: lockSvg,
                    active: focus.isLocked(),
                },
                // {
                //     name: EventActionIds?.pinEvent,
                //     image: PinEvent,
                //     active: false,
                // },
            ];
        } else {
            actionsArray = [
                {
                    name: EventActionIds?.editEvent,
                    image: editSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.addEventForDeletion,
                    image: deleteSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.bypassEvent,
                    image: bypassSvg,
                    active: focus.isBypassed(),
                },
                {
                    name: EventActionIds?.lockEvent,
                    image: lockSvg,
                    active: focus.isLocked(),
                },
            ];
        }
        // const isFocusBaseline =
        //     baseline &&
        //     baseline.data &&
        //     baseline.data.nodes.filter((node) => {
        //         return node.id === focus.id;
        //     });

        // const isBaselineNode = isFocusBaseline && isFocusBaseline.length > 0;

        const actions = actionsArray.map((action, i) => {
            if (this.props.inBaseline && action.name === "Lock") {
                return null;
            }
            return (
                <div
                    key={i}
                    className="NodeIconContainer"
                    onClick={() => this.onHandleClick(action.name)}
                    id={action.name}
                >
                    <img
                        alt="alt"
                        className={
                            action.active ? "NodeIconActive" : "NodeIcon"
                        }
                        src={action.image}
                    />
                </div>
            );
        });

        let component;
        switch (focus.type) {
            case startObject.constant():
                component = (
                    <StartCard
                        closeMenu={this.closeMenu}
                        data={focus}
                        history={this.props.history}
                        loadedScenario={this.props.loadedScenario}
                        updateBaselineCanvas={this.props.updateBaselineCanvas}
                        manager={this.props.manager}
                        getUserScenarios={this.props.getUserScenarios}
                        loadScenario={this.props.loadScenario}
                        baseline={this.props.baseline}
                        baselineDataManager={this.props.baselineDataManager}
                        resetFocus={this.props.resetFocus}
                        inBaseline={this.props.inBaseline}
                        updateScenario={this.props.updateScenario}
                        toggleShowAgencyBaselineView={
                            this.props.toggleShowAgencyBaselineView
                        }
                        accountData={this.props.accountData}
                        showLogin={this.props.showLogin}
                    />
                );
                break;
            case meObject.constant():
                component = (
                    <MeCard
                        closeMenu={this.closeMenu}
                        data={focus}
                        history={this.props.history}
                        loadedScenario={this.props.loadedScenario}
                        updateBaselineCanvas={this.props.updateBaselineCanvas}
                        manager={this.props.manager}
                        getUserScenarios={this.props.getUserScenarios}
                        loadScenario={this.props.loadScenario}
                        baseline={this.props.baseline}
                        baselineDataManager={this.props.baselineDataManager}
                        resetFocus={this.props.resetFocus}
                        inBaseline={this.props.inBaseline}
                        updateScenario={this.props.updateScenario}
                        toggleShowAgencyBaselineView={
                            this.props.toggleShowAgencyBaselineView
                        }
                        accountData={this.props.accountData}
                        showLogin={this.props.showLogin}
                    />
                );
                break;
            case businessObject.constant():
                component = businessObject.eventNodeMenu.call(
                    businessObject,
                    focus
                );
                break;
            case incomeObject.constant():
                component = incomeObject.eventNodeMenu.call(
                    incomeObject,
                    focus
                );
                break;
            case expenseObject.constant():
                component = expenseObject.eventNodeMenu.call(
                    expenseObject,
                    focus,
                    this.state.inflation
                );
                break;
            case tuitionObject.constant():
                component = tuitionObject.eventNodeMenu.call(
                    tuitionObject,
                    focus,
                    this.state.inflation
                );
                break;
            case mortgageObject.constant():
                component = mortgageObject.eventNodeMenu(focus);
                break;
            case loanObject.constant():
                component = loanObject.eventNodeMenu(focus);
                break;
            case studentLoanObject.constant():
                component = studentLoanObject.eventNodeMenu(focus);
                break;
            case bankObject.constant():
                const entity = Object.values(
                    getRelevantEntities(
                        focus.entities.map((entity) => entity.id)
                    )
                )[0];
                if (entity.data.bankSelected === "plaid") {
                    component = bankPlaidObject.eventNodeMenu(focus);
                    break;
                } else if (entity.data.bankSelected === "flinks") {
                    component = bankObject.eventNodeMenu(focus);
                } else {
                    component = bankAltObject.eventNodeMenu(focus);
                }
                break;
            case budgetObject.constant():
                if (focus.metadata.beta) {
                    component = <BudgetCard data={focus} />;
                } else {
                    component = budgetObject.eventNodeMenu(focus);
                }
                break;
            case studentBudgetObject.constant():
                component = studentBudgetObject.eventNodeMenu(focus);
                break;
            case goalObject.constant():
                component = goalObject.eventNodeMenu(focus);
                break;
            case rentObject.constant():
                component = rentObject.eventNodeMenu.call(
                    rentObject,
                    focus,
                    this.props.getWalkScore
                );
                break;
            case houseObject.constant():
                component = houseObject.eventNodeMenu.call(
                    houseObject,
                    focus,
                    this.props.getWalkScore
                );
                break;
            case incomeTaxObject.constant():
                component = (
                    <IncomeTaxCard data={focus} threadsAlias={threadsAlias} />
                );
                break;
            case bankPlaidObject.constant():
                component = bankPlaidObject.eventNodeMenu.call(
                    bankPlaidObject,
                    focus
                );
                break;
            case maternityObject.constant():
                component = maternityObject.eventNodeMenu.call(
                    maternityObject,
                    focus
                );
                break;
            case downpaymentObject.constant():
                component = downpaymentObject.eventNodeMenu.call(
                    downpaymentObject,
                    focus
                );
                break;
            case equityObject.constant():
                component = equityObject.eventNodeMenu.call(
                    equityObject,
                    focus
                );
                break;
            case rrspObject.constant():
                component = rrspObject.eventNodeMenu.call(rrspObject, focus);
                break;
            case propertyTransferTaxObject.constant():
                component = propertyTransferTaxObject.eventNodeMenu.call(
                    propertyTransferTaxObject,
                    focus
                );
                break;
            case investmentObject.constant():
                component = investmentObject.eventNodeMenu.call(
                    investmentObject,
                    focus
                );
                break;
            case taxDeductibleObject.constant():
                component = taxDeductibleObject.eventNodeMenu.call(
                    taxDeductibleObject,
                    focus
                );
                break;
            case propertyValueObject.constant():
                component = propertyValueObject.eventNodeMenu.call(
                    propertyValueObject,
                    focus
                );
                break;
            case propertyResaleObject.constant():
                component = propertyResaleObject.eventNodeMenu.call(
                    propertyResaleObject,
                    focus
                );
                break;
            case modifierObject.constant():
                component = modifierObject.eventNodeMenu.call(
                    modifierObject,
                    focus
                );
                break;
            case modifier2Object.constant():
                component = modifier2Object.eventNodeMenu.call(
                    modifier2Object,
                    focus
                );
                break;
            case expressionObject.constant():
                component = expressionObject.eventNodeMenu.call(
                    expressionObject,
                    focus
                );
                break;
            case customerTransferObject.constant():
                component = customerTransferObject.eventNodeMenu.call(
                    customerTransferObject,
                    focus
                );
                break;
            case employeeObject.constant():
                component = employeeObject.eventNodeMenu.call(
                    employeeObject,
                    focus
                );
                break;
            case lumpSumPaymentObject.constant():
                component = lumpSumPaymentObject.eventNodeMenu.call(
                    lumpSumPaymentObject,
                    focus
                );
                break;
            case renewalRefinanceObject.constant():
                component = renewalRefinanceObject.eventNodeMenu.call(
                    renewalRefinanceObject,
                    focus
                );
                break;
            case realEstateFeeObject.constant():
                component = realEstateFeeObject.eventNodeMenu.call(
                    realEstateFeeObject,
                    focus
                );
                break;
            case retirementObject.constant():
                component = retirementObject.eventNodeMenu.call(
                    retirementObject,
                    focus
                );
                break;
            case nullObject.constant():
                component = nullObject.eventNodeMenu.call(nullObject, focus);
                break;
            case containerObject.constant():
                component = containerObject.eventNodeMenu.call(
                    containerObject,
                    focus
                );
                break;
            case graduationObject.constant():
                component = graduationObject.eventNodeMenu.call(
                    graduationObject,
                    focus
                );
                break;
            case pensionObject.constant():
                component = pensionObject.eventNodeMenu.call(
                    pensionObject,
                    focus
                );
                break;
            case mortgageInsuranceObject.constant():
                component = mortgageInsuranceObject.eventNodeMenu.call(
                    mortgageInsuranceObject,
                    focus
                );
                break;
            case capitalGainsObject.constant():
                component = capitalGainsObject.eventNodeMenu.call(
                    capitalGainsObject,
                    focus
                );
                break;
            case closingCostsObject.constant():
                component = closingCostsObject.eventNodeMenu.call(
                    closingCostsObject,
                    focus
                );
                break;
            case homeMaintenanceObject.constant():
                component = homeMaintenanceObject.eventNodeMenu.call(
                    homeMaintenanceObject,
                    focus
                );
                break;
            case shortTermRentalObject.constant():
                component = shortTermRentalObject.eventNodeMenu.call(
                    shortTermRentalObject,
                    focus
                );
                break;
            case rentalIncomeObject.constant():
                component = rentalIncomeObject.eventNodeMenu.call(
                    rentalIncomeObject,
                    focus
                );
                break;
            case debitCreditObject.constant():
                component = debitCreditObject.eventNodeMenu.call(
                    debitCreditObject,
                    focus
                );
                break;
            case debtObject.constant():
                component = debtObject.eventNodeMenu.call(debtObject, focus);
                break;
            case grantObject.constant():
                component = grantObject.eventNodeMenu.call(grantObject, focus);
                break;
            case debtRepaymentObject.constant():
                component = debtRepaymentObject.eventNodeMenu.call(
                    debtRepaymentObject,
                    focus
                );
                break;
            case customerObject.constant():
                component = customerObject.eventNodeMenu.call(
                    customerObject,
                    focus
                );
                break;
            case projectsObject.constant():
                component = projectsObject.eventNodeMenu.call(
                    projectsObject,
                    focus
                );
                break;
            case customer2Object.constant():
                component = customer2Object.eventNodeMenu.call(
                    customer2Object,
                    focus
                );
                break;
            case kpiObject.constant():
                component = kpiObject.eventNodeMenu.call(kpiObject, focus);
                break;
            case instanceObject.constant():
                component = instanceObject.eventNodeMenu.call(
                    instanceObject,
                    focus
                );
                break;
            case customerGrowthObject.constant():
                component = customerGrowthObject.eventNodeMenu.call(
                    customerGrowthObject,
                    focus
                );
                break;
            case customerGrowth2Object.constant():
                component = customerGrowth2Object.eventNodeMenu.call(
                    customerGrowth2Object,
                    focus
                );
                break;
            case customerChurn2Object.constant():
                component = customerChurn2Object.eventNodeMenu.call(
                    customerChurn2Object,
                    focus
                );
                break;
            case customerTransfer2Object.constant():
                component = customerTransfer2Object.eventNodeMenu.call(
                    customerTransfer2Object,
                    focus
                );
                break;
            case customerChurnObject.constant():
                component = customerChurnObject.eventNodeMenu.call(
                    customerChurnObject,
                    focus
                );
                break;
            case unitCostObject.constant():
                component = unitCostObject.eventNodeMenu.call(
                    unitCostObject,
                    focus
                );
                break;
            case revenueObject.constant():
                component = revenueObject.eventNodeMenu.call(
                    revenueObject,
                    focus
                );
                break;
            case Student_IncomeObject.constant():
                component = Student_IncomeObject.eventNodeMenu.call(
                    Student_IncomeObject,
                    focus
                );
                break;
            case condoFeeObject.constant():
                component = condoFeeObject.eventNodeMenu.call(
                    condoFeeObject,
                    focus
                );
                break;
            case cacObject.constant():
                component = cacObject.eventNodeMenu.call(cacObject, focus);
                break;
            case contractObject.constant():
                component = contractObject.eventNodeMenu.call(
                    contractObject,
                    focus
                );
                break;
            case groupObject.constant():
                component = groupObject.eventNodeMenu.call(groupObject, focus);
                break;
            case projectObject.constant():
                component = projectObject.eventNodeMenu.call(
                    projectObject,
                    focus
                );
                break;
            case initialBalanceObject.constant():
                component = initialBalanceObject.eventNodeMenu.call(
                    initialBalanceObject,
                    focus
                );
                break;
            case importObject.constant():
                component = importObject.eventNodeMenu.call(
                    importObject,
                    focus
                );
                break;
            case accountImportObject.constant():
                component = accountImportObject.eventNodeMenu.call(
                    accountImportObject,
                    focus
                );
                break;
            case growthObject.constant():
                component = growthObject.eventNodeMenu.call(
                    growthObject,
                    focus
                );
                break;
            case outboundSalesObject.constant():
                component = outboundSalesObject.eventNodeMenu.call(
                    outboundSalesObject,
                    focus
                );
                break;
            case salespersonObject.constant():
                component = salespersonObject.eventNodeMenu.call(
                    salespersonObject,
                    focus
                );
                break;
            case segmentObject.constant():
                component = segmentObject.eventNodeMenu.call(
                    segmentObject,
                    focus
                );
                break;
            case allocationObject.constant():
                component = allocationObject.eventNodeMenu.call(
                    allocationObject,
                    focus
                );
                break;
            case websiteVisitorsObject.constant():
                component = websiteVisitorsObject.eventNodeMenu.call(
                    websiteVisitorsObject,
                    focus
                );
                break;
            case campaignObject.constant():
                component = campaignObject.eventNodeMenu.call(
                    campaignObject,
                    focus
                );
                break;
            case CAC2Object.constant():
                component = CAC2Object.eventNodeMenu.call(CAC2Object, focus);
                break;
            case constraintObject.constant():
                component = constraintObject.eventNodeMenu.call(
                    constraintObject,
                    focus
                );
                break;
            case accountModifierObject.constant():
                component = accountModifierObject.eventNodeMenu.call(
                    accountModifierObject,
                    focus
                );
                break;
            case percentageObject.constant():
                component = percentageObject.eventNodeMenu.call(
                    percentageObject,
                    focus
                );
                break;
            case resourceObject.constant():
                component = percentageObject.eventNodeMenu.call(
                    resourceObject,
                    focus
                );
                break;
            case utilizationObject.constant():
                component = percentageObject.eventNodeMenu.call(
                    utilizationObject,
                    focus
                );
                break;
            case capacityObject.constant():
                component = percentageObject.eventNodeMenu.call(
                    capacityObject,
                    focus
                );
                break;
            case averageOrderObject.constant():
                component = percentageObject.eventNodeMenu.call(
                    averageOrderObject,
                    focus
                );
                break;
            case accountExpressionObject.constant():
                component = percentageObject.eventNodeMenu.call(
                    accountExpressionObject,
                    focus
                );
                break;
            case unitObject.constant():
                component = unitObject.eventNodeMenu(focus);
                break;
            default:
        }
        let eventName;
        try {
            eventName = getObjectFromUUID(focus.type)?.name();
        } catch {
            eventName = "error";
        }
        // TODO FOCUS.NAME should be the event type, not actual name
        return (
            <div className={inBaseline ? "NodeFocusMenuFull" : "NodeFocusMenu"}>
                <div className="NodeHeader">
                    <div className="NodeSelected">{focus.name}</div>
                    <img
                        alt="alt"
                        src={closeSvg}
                        className="NodeMenuClose"
                        onClick={this.closeMenu}
                    />
                </div>
                <div className="NodeType">{eventName}</div>
                <div className="NodeActionContainer">{actions}</div>
                {focus && (
                    <div className="NodeDescription">{focus.description}</div>
                )}
                {focus.isNodeExpired() && (
                    <div className="expiredNode">
                        {" "}
                        <div className="expiredNodeText">
                            This Event&apos;s end date is in the past and will
                            no longer be included in calculations. Click the
                            “Edit” button to modify the end date.
                        </div>
                    </div>
                )}
                {focus.isNodeOutdated() && (
                    <div className="expiredNode">
                        {" "}
                        <div className="expiredNodeText">
                            This Event has recently been updated. Please click
                            the “Edit” button refresh the state of the node to
                            compile, or re-add the node into the scenario.
                        </div>
                    </div>
                )}
                {/* {isBaselineNode && !inBaseline && (
                    <div
                        onClick={this.handleViewBaseline}
                        className="NodeMenuViewBaselineButton"
                    >
                        View & Edit in My Baseline
                    </div>
                )}
                {!isBaselineNode && !inBaseline && focus.type !== "Me" && (
                    <div
                        onClick={this.handleAddToBaselinePopUp}
                        className="NodeMenuAddBaselineButton"
                    >
                        + Add to My Baseline
                    </div>
                )}
                {!focus.isLibraryEvent &&
                    supportedLibraryEvents
                        .map((category) => category.type)
                        .includes(focus.type) && (
                        <div
                            onClick={this.handleAddToLibrary}
                            className="NodeMenuAddBaselineButton"
                        >
                            + Add to My Library
                        </div>
                    )}
                {CONTAINER_EXPERIMENTAL &&
                    !isBaselineNode &&
                    !inBaseline &&
                    focus.type !== "Me" && (
                        <div
                            onClick={this.handleCreateContainer}
                            className="NodeMenuAddBaselineButton"
                        >
                            + Create a Container
                        </div>
                    )} */}
                <div className="NodeFocusToggle">
                    <div
                        onClick={() => this.onHandleCardState("info")}
                        className={
                            cardState === "info"
                                ? "NodeOptionActive"
                                : "NodeOption"
                        }
                    >
                        Info
                    </div>
                    <div
                        // onClick={() => this.onHandleCardState("comment")}
                        onClick={() => {
                            return;
                        }}
                        className={
                            cardState === "comment"
                                ? "NodeOptionActive"
                                : "NodeOptionDisabled"
                        }
                    >
                        Comment
                    </div>
                </div>
                {cardState === "info" ? (
                    <div> {component}</div>
                ) : (
                    <ScenarioComment
                        data={this.props.loadedScenario}
                        editScenario={this.props.editScenario}
                        manager={this.props.manager}
                        fetchSharedScenarios={this.props.fetchSharedScenarios}
                        loadScenario={this.props.loadScenario}
                        userScenarios={this.props.userScenarios}
                        sharedScenario={this.props.sharedScenario}
                        getUserScenarios={this.props.getUserScenarios}
                        focus={this.props.focus}
                        loadedScenario={this.props.loadedScenario}
                        editScenarioComments={this.props.editScenarioComments}
                    />
                )}

                {showBaselinePopUp && (
                    <EventsPopUp
                        onHandleClose={this.cancelAddToBaseline}
                        specificClass={"EventMenu"}
                    >
                        <div className="baselinePopUpText">
                            Associate a new event with an exisiting Person,
                            create a new Person, or start a new Thread
                        </div>
                        <div className="baselineNodeContainer">
                            {baselineNode ? (
                                baselineNode.map((node, i) => {
                                    const isBaseline = node.type === "Baseline";
                                    if (
                                        node.type === "Me" ||
                                        node.type === "Person" ||
                                        isBaseline
                                    ) {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() =>
                                                    this.handleChangeSelected(
                                                        node
                                                    )
                                                }
                                                className={
                                                    baselineNodeSelected.id ===
                                                    node.id
                                                        ? "baselineNodeActive"
                                                        : "baselineNode"
                                                }
                                            >
                                                <img
                                                    src={
                                                        isBaseline
                                                            ? addOptionSvg
                                                            : personBlack
                                                    }
                                                    className="optionSvg"
                                                    alt="alt"
                                                />
                                                <div className="optionName">
                                                    {isBaseline
                                                        ? "New Thread"
                                                        : node.name}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return <></>;
                                })
                            ) : (
                                <div style={{ display: "flex" }}>
                                    <div
                                        onClick={() =>
                                            this.handleBaselineSelected(
                                                "meNode"
                                            )
                                        }
                                        className={
                                            noBaselineNodeSelected === "meNode"
                                                ? "baselineNodeActive"
                                                : "baselineNode"
                                        }
                                    >
                                        <img
                                            src={personBlack}
                                            className="optionSvg"
                                            alt="alt"
                                        />
                                        <div className="optionName">Me</div>
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleBaselineSelected(
                                                "newThread"
                                            )
                                        }
                                        className={
                                            noBaselineNodeSelected ===
                                            "newThread"
                                                ? "baselineNodeActive"
                                                : "baselineNode"
                                        }
                                    >
                                        <img
                                            src={addOptionSvg}
                                            className="optionSvg"
                                            alt="alt"
                                        />
                                        <div className="optionName">
                                            New Thread
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div
                                onClick={() =>
                                    this.handleChangeSelected({
                                        id: "newPerson",
                                    })
                                }
                                className={
                                    noBaselineNodeSelected === "newPerson" ||
                                    (baselineNodeSelected &&
                                        baselineNodeSelected.id === "newPerson")
                                        ? "baselineNodeActive"
                                        : "baselineNode"
                                }
                            >
                                <img
                                    src={personBlack}
                                    className="optionSvg"
                                    alt="alt"
                                />
                                <div className="optionName">New Person</div>
                            </div>
                        </div>
                        <div className="baselineBtnContainer">
                            <div
                                onClick={this.cancelAddToBaseline}
                                className="baselineCancel"
                            >
                                Cancel
                            </div>
                            <div
                                onClick={this.handleAddToBaseline}
                                className="baselineSubmit"
                            >
                                Add to Baseline
                            </div>
                        </div>
                    </EventsPopUp>
                )}
            </div>
        );
    }
}

export default NodeMenu;
