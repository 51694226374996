import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";
import { camelCase } from "lodash";

import {
    stringToYyyyMmDdDate,
    stringToAmortizedCadence,
    handleCadenceChange,
    handleAmortizationPeriodChange,
    handleAmortizedStartDateChange,
    handleAmortizedEndDateChange,
} from "./helpers/amortizedHelpers";

export const getAmortizedEndDate = (
    start: string,
    cadence: string,
    amortizationPeriod: number
) => {
    if (!start || !amortizationPeriod || !cadence) {
        return null;
    } else {
        let endDate = moment(start);
        switch (cadence) {
            case "annually":
                endDate = endDate.add(amortizationPeriod - 1, "years");
                break;
            case "quarterly":
                endDate = endDate.add(amortizationPeriod * 3 - 3, "months");
                break;
            case "monthly":
                endDate = endDate.add(amortizationPeriod - 1, "months");
                break;
            case "semi-monthly":
                endDate = endDate.add(amortizationPeriod - 1, "months");
                endDate = endDate.add(16, "days");
                break;
            case "weekly":
                endDate = endDate.add(amortizationPeriod - 1, "weeks");
                break;
            case "bi-weekly":
                endDate = endDate.add(amortizationPeriod - 2, "weeks");
                break;
            case "daily":
                endDate = endDate.add(amortizationPeriod - 1, "days");
                break;
            default:
            //noop
        }

        return endDate.format("YYYY-MM-DD");
    }
};

export const calculateAmortizedEndDate = (
    start,
    amortizationPeriod,
    cadence,
    entitiesMap,
    currentEntity
) => {
    const data = { ...(entitiesMap[currentEntity]?.data || {}) };
    data.amortizedEnd = getAmortizedEndDate(start, cadence, amortizationPeriod);
    return data.amortizedEnd;
};

export const getNumPayments = (cadence: string, amortizationPeriod: number) => {
    if (!cadence || !amortizationPeriod) {
        return 1;
    } else {
        let numPayments;
        switch (cadence) {
            case "annually":
            case "quarterly":
            case "monthly":
            case "weekly":
            case "daily":
            case "one-time":
                numPayments = amortizationPeriod;
                break;
            case "semi-monthly":
                numPayments = amortizationPeriod * 2;
                break;
            case "bi-weekly":
                numPayments = Math.floor(amortizationPeriod / 2);
                break;
            default:
            //noop
        }
        return numPayments;
    }
};

export const calculateAmortizationPeriod = (
    start: string,
    end: string,
    cadence: string
): number | null => {
    if (!start || !end || !cadence) {
        return null;
    } else {
        const startDate = moment(start);
        const endDate = moment(end);
        let amortizationPeriod: number | null = null;

        // TODO: add fractional behaviour
        switch (cadence) {
            case "annually":
                amortizationPeriod = endDate.diff(startDate, "years");
                break;
            case "quarterly":
                amortizationPeriod = endDate.diff(startDate, "years") * 4;
                break;
            case "monthly":
                amortizationPeriod = endDate.diff(startDate, "months");
                break;
            case "semi-monthly":
                amortizationPeriod = endDate.diff(startDate, "months") * 2;
                break;
            case "weekly":
                amortizationPeriod = endDate.diff(startDate, "weeks");
                break;
            case "bi-weekly":
                amortizationPeriod = endDate.diff(startDate, "weeks") * 2;
                break;
            case "daily":
                amortizationPeriod = endDate.diff(startDate, "days");
                break;
            default:
            //noop
        }

        return amortizationPeriod;
    }
};

export default function expenseInputsHandler(
    value: string,
    id:
        | "value"
        | "amortizedValue"
        | "startDate"
        | "endDate"
        | "entityName"
        | "bypassState"
        | "cadence"
        | "amortizationPeriod"
        | "inflationRate"
        | "accountName"
        | "contraAccountName",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "value":
            data.cost = value;
            data.value = parseInt(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "amortizedValue":
            const paymentAmount =
                parseInt(value) /
                newEntitiesMap[currentEntity].data.numPayments;

            data.cost = value;
            data.value = paymentAmount.toFixed(2);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "bypassState":
            currentEntityObject.bypassState = !!value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDate = stringToYyyyMmDdDate(value);

            if (startDate == null) {
                console.warn("failed to parse start date");
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (data.expenseType === "amortized") {
                newEntitiesMap[currentEntity] = handleAmortizedStartDateChange(
                    currentEntityObject,
                    startDate
                );
            } else {
                currentEntityObject.startDate = startDate;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }

            // Update all modifier/override startDates
            if (currentEntityObject?.data?.modsCreated) {
                for (const mod of currentEntityObject.data.modsCreated) {
                    mod.startDate = startDate;
                }
            }

            break;
        case "endDate":
            const endDate = stringToYyyyMmDdDate(value);

            if (endDate == null) {
                console.warn("failed to parse end date");
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (data.expenseType === "amortized") {
                newEntitiesMap[currentEntity] = handleAmortizedEndDateChange(
                    currentEntityObject,
                    endDate
                );
            } else {
                currentEntityObject.endDate = endDate;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "cadence":
            if (data.expenseType === "amortized") {
                const cadence = stringToAmortizedCadence(value);

                if (cadence == null) {
                    console.warn("failed to parse cadence");
                    newEntitiesMap[currentEntity] = currentEntityObject;
                    break;
                }

                newEntitiesMap[currentEntity] = handleCadenceChange(
                    currentEntityObject,
                    cadence
                );
            } else {
                currentEntityObject.cadence = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "amortizationPeriod":
            newEntitiesMap[currentEntity] = handleAmortizationPeriodChange(
                currentEntityObject,
                parseFloat(value)
            );
            const paymentAmount2 =
                data.amortizedValue /
                newEntitiesMap[currentEntity].data.numPayments;

            newEntitiesMap[currentEntity].data.calulatedAmortizedValue =
                paymentAmount2.toFixed(2);

            break;
        case "inflationRate":
            data.inflationRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name;
            data.accountIds = account.ids;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name ?? "None (default)";
            data.contraAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
}
