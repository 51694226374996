import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import AccountData from "../../../helpers/ledgers/accountsAndLedgers.json";
import {
    stringToYyyyMmDdDate,
    stringToAmortizedCadence,
    handleCadenceChange,
    handleAmortizationPeriodChange,
    handleAmortizedStartDateChange,
    handleAmortizedEndDateChange,
} from "./helpers/amortizedHelpers";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const debitCreditInputsHandler = (
    value: string,
    id:
        | "value"
        | "entityName"
        | "bypassState"
        | "startDate"
        | "endDate"
        | "cadence"
        | "rating"
        | "debitOrCreditType"
        | "accountName"
        | "contraAccountName"
        | "amortizedValue"
        | "amortizationPeriod",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "value":
            data.amount = value;
            data.value = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "bypassState":
            currentEntityObject.bypassState = !!value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDate = stringToYyyyMmDdDate(value);

            if (startDate == null) {
                console.warn("failed to parse start date");
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (data.amortizedOrOngoing === "amortized") {
                newEntitiesMap[currentEntity] = handleAmortizedStartDateChange(
                    currentEntityObject,
                    startDate
                );
            } else {
                currentEntityObject.startDate = startDate;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }

            // Update all modifier/override startDates
            if (currentEntityObject?.data?.modsCreated) {
                for (const mod of currentEntityObject.data.modsCreated) {
                    mod.startDate = startDate;
                }
            }

            break;
        case "endDate":
            const endDate = stringToYyyyMmDdDate(value);

            if (endDate == null) {
                console.warn("failed to parse end date");
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (data.expenseType === "amortized") {
                newEntitiesMap[currentEntity] = handleAmortizedEndDateChange(
                    currentEntityObject,
                    endDate
                );
            } else {
                currentEntityObject.endDate = endDate;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "cadence":
            if (data.expenseType === "amortized") {
                const cadence = stringToAmortizedCadence(value);

                if (cadence == null) {
                    console.warn("failed to parse cadence");
                    newEntitiesMap[currentEntity] = currentEntityObject;
                    break;
                }

                newEntitiesMap[currentEntity] = handleCadenceChange(
                    currentEntityObject,
                    cadence
                );
            } else {
                currentEntityObject.cadence = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "debitOrCreditType":
            data.debitOrCredit = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name ?? "None (default)";
            data.contraAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "amortizedValue":
            data.amortizedValue = value;
            const paymentAmount =
                parseFloat(value) /
                newEntitiesMap[currentEntity].data.numPayments;
            data.calulatedAmortizedValue = paymentAmount.toFixed(2);

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "amortizationPeriod":
            newEntitiesMap[currentEntity] = handleAmortizationPeriodChange(
                currentEntityObject,
                parseFloat(value)
            );
            const paymentAmount2 =
                data.amortizedValue /
                newEntitiesMap[currentEntity].data.numPayments;

            newEntitiesMap[currentEntity].data.calulatedAmortizedValue =
                paymentAmount2.toFixed(2);

            break;
        default:
    }
    return newEntitiesMap;
};

export function getContraAccounts(account) {
    const accounts = Object.values(AccountData);
    const contraAccounts: string[] = [];

    if (!account || account.trim() == "") {
        return contraAccounts;
    }

    accounts.forEach((acc) => {
        if (acc.id === account) {
            contraAccounts.unshift(acc.id);
            let currentAcc = acc;
            while (currentAcc.parents.length > 0) {
                currentAcc = AccountData[currentAcc.parents[0]];
                contraAccounts.unshift(currentAcc.id);
            }
        }
    });

    return contraAccounts;
}
