import { getRelevantEntities } from "actions/getNodeEntityActions";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";

/*
 * Helper function that handles Unit Cost Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
const utilizationInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "resource"
        | "value"
        | "accountName"
        | "contraAccountName",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    data.lastUpdated = moment().format("YYYY-MM-DD");

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "resource":
            const parsedResource = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedResource,
                eventId,
                currentEntityObject.id,
                data.customerEventId,
                data.customerIds
            );

            data.selectedEvent = parsedResource;
            data.selectedEventId = parsedResource?.eventId ?? "";
            data.selectedIds = parsedResource?.entityIds ?? [];

            const resourceEntity = Object.values(
                getRelevantEntities(parsedResource?.entityIds)
            )[0];
            data.resourceValue = resourceEntity?.data?.value ?? 0;
            data.resourceStart = resourceEntity.startDate;
            data.resourceEnd = resourceEntity.endDate;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            data.value = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name ?? "";
            data.contraAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default utilizationInputsHandler;
