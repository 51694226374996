import type { LedgerMetadataUnit } from "reducers/typesSchema/allAccountLedgersSchema";
import type { ChartGraphSchema } from "reducers/typesSchema/chartGraphSchema";
import type {
    CalculatedThreadsSchema,
    GoalNodeDataObjectSchema,
} from "reducers/typesSchema/calculatedThreadsSchema";
import { create, Scrollbar, color } from "@amcharts/amcharts4/core";
import {
    XYChart,
    DateAxis,
    ValueAxis,
    XYCursor,
} from "@amcharts/amcharts4/charts";
import { updateDateAxis, updateValueAxis } from "./updateAxes";
import { buildPointsData } from "./buildPointsData";
import { buildPointsDataPerEntityGraphing } from "./buildPointsDataPerEntityGraphing";
import { createSeries } from "./createSeries";
import { createGoalSeries, createGoalRange } from "./createGoalSeries";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { createTodayRange } from "./createTodayRange";

export interface DrawGraphOptions {
    xAxisZoom: [start: number, end: number];
    scrollBarColor: string;
}

const drawGraphOptionsDefault: DrawGraphOptions = {
    xAxisZoom: [0, 100],
    scrollBarColor: "#93C0FF",
};

export function drawGraph(
    {
        selectedAccounts,
        activeThreadId,
        monthlyCumulative,
        nodeIds,
    }: // scenarioId,
    ChartGraphSchema & { activeThreadId: string },
    selectedThreads: string[],
    calculatedThreads: CalculatedThreadsSchema,
    ledgerMetadataUnit: LedgerMetadataUnit,
    filteredEventAndEntityData: any[],
    options?: Partial<DrawGraphOptions>
) {
    let mergedOptions = drawGraphOptionsDefault;
    // Merge default options with what the caller passed.
    if (options) {
        mergedOptions = { ...drawGraphOptionsDefault, ...options };
    }

    // Get all the required calculatedThread objects as an array
    let _selectedThreads = selectedThreads;
    if (activeThreadId && !_selectedThreads.includes(activeThreadId)) {
        _selectedThreads = [activeThreadId, ...selectedThreads];
    }
    const calculatedThreadsArray = _selectedThreads
        .map((threadId) => calculatedThreads[threadId])
        .filter((_calculatedThread) => {
            /**
             * If the selected thread's id is not in calculatedThreads, we ignore it.
             * This can happen if the user has selected threads and then adds a node in the middle
             * of the scenario. The threadIds would change due to that new node.
             */
            return !!_calculatedThread;
        });

    const xyChart = create("Whatifi-Line-Graph2", XYChart); // TODO: "Whatifi-Line-Graph2" should be dynamic

    // Set input format for the dates
    xyChart.dateFormatter.inputDateFormat = "yyyy-MM";
    // Set input format for the number
    xyChart.numberFormatter.numberFormat = "#,###.";

    // Create axes of the chart
    const dateAxis = xyChart.xAxes.push(new DateAxis());
    updateDateAxis(dateAxis, mergedOptions?.xAxisZoom);
    const valueAxis = xyChart.yAxes.push(new ValueAxis());
    updateValueAxis(valueAxis, ledgerMetadataUnit);

    let tooltipFontSize = "15px";
    if (calculatedThreadsArray.length > 4) tooltipFontSize = "10px";
    else if (calculatedThreadsArray.length > 2) tooltipFontSize = "14px";

    const goalNodeDict: { [identifier: string]: GoalNodeDataObjectSchema } = {};

    const selectedAccountsArray = Object.values(selectedAccounts ?? {});

    // Build a series of points for each thread.
    calculatedThreadsArray.forEach((calculatedThread) => {
        selectedAccountsArray?.forEach(({ name, id }) => {
            // Step 1: Find the ledger name to look up
            let ledgerName = name;
            let ledgerId = id;
            if (monthlyCumulative === "cumulative") {
                // eslint-disable-next-line
                ledgerName = `Cumulative ${ledgerName}`;
                ledgerId = `Cumulative-${ledgerId}`;
            }
            // Step 2: Determine if we are performing per entity / event graphing, then build an array of { date: string, value: number }[], which correspond to each point in the graph.
            if (filteredEventAndEntityData.length > 0) {
                filteredEventAndEntityData.forEach((dataItem) => {
                    const pointsData = buildPointsDataPerEntityGraphing(
                        dataItem.id,
                        calculatedThread,
                        ledgerId
                    );
                    // Step 3: Create a series of points (and line) for this thread.
                    const series = createSeries({
                        pointsData,
                        calculatedThread,
                        activeThreadId,
                        ledgerMetadataUnit,
                        tooltipFontSize,
                        dataItem,
                        ledgerName,
                    });
                    // Step 4: Add the series to the chart
                    xyChart.series.push(series);
                });
            } else {
                const pointsData = buildPointsData(
                    calculatedThread.ledgersData[ledgerId],
                    nodeIds
                );
                // Step 3: Create a series of points (and line) for this thread.
                const series = createSeries({
                    pointsData,
                    calculatedThread,
                    activeThreadId,
                    ledgerMetadataUnit,
                    tooltipFontSize,
                    ledgerName,
                });
                // Step 4: Add the series to the chart
                xyChart.series.push(series);
            }

            // Step 5: Find all the unique goal nodes in the threads that have been chosen.
            // We add each goal node to the chart after we leave the current loop.
            // Goal Nodes don't necessarily need to be uniquely charted once since we can give them goalNode.identifier as their id, but this will be more
            // efficient because we only chart each unique goal node once.
            calculatedThread.goalNode.data.forEach((goalNodeDataObject) => {
                if (
                    goalNodeDataObject.accountType === ledgerId &&
                    !goalNodeDict?.[goalNodeDataObject.identifier]
                ) {
                    // If this goal node is of the account ledger we want, AND, it is the first time we're seeing it (so we don't duplicate between multiple threads)
                    goalNodeDict[goalNodeDataObject.identifier] =
                        goalNodeDataObject;
                }
            });
        });
    });

    // For each goal, get its relevant entity and determine whether a marker(createGoalSeries) or threshold(createGoalRange) should be rendered.
    const goalNodes = Object.values(goalNodeDict);
    goalNodes.forEach((goalNode) => {
        const goalEntityId = goalNode?.identifier ?? "";
        if (!goalEntityId) return;

        const goalEntityData = Object.values(
            getRelevantEntities([goalEntityId]) ?? {}
        )?.[0]?.data;

        if (goalEntityData?.displayMarker) {
            const goalSeries = createGoalSeries(goalNode);
            xyChart.series.push(goalSeries);
        }

        if (goalEntityData?.displayThreshold) {
            createGoalRange(goalNode, valueAxis);
        }
    });

    // Create bullet for todays date
    createTodayRange(dateAxis);

    // Make a panning cursor
    xyChart.cursor = new XYCursor();
    xyChart.cursor.behavior = "zoomXY";

    // Create vertical scrollbar and place it before the value axis
    xyChart.scrollbarY = new Scrollbar();
    xyChart.scrollbarY.parent = xyChart.leftAxesContainer;
    xyChart.scrollbarY.toBack();
    xyChart.scrollbarY.background.fill = color(mergedOptions.scrollBarColor);

    // Create a horizontal scrollbar with previe and place it underneath the date axis
    xyChart.scrollbarX = new Scrollbar();
    xyChart.scrollbarX.parent = xyChart.bottomAxesContainer;
    xyChart.scrollbarX.toBack();
    xyChart.scrollbarX.background.fill = color(mergedOptions.scrollBarColor);

    // Legacy. There is a listener at the Component level that determines width and height dynamically.
    // xyChart.width = 400;
    // xyChart.height = 150;

    return xyChart;
}
