// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { resourceObject } from "Components/Registry/Resource";
import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    RequiredStar,
    ModalRowHalf,
    SelectDropDown,
    SelectEntities,
    Rate,
    MuiCalendar,
    EntitySummaryHighlightBlank,
    ToggledContainer,
    ToggledContainerHalf,
    Value,
    GenericButton,
    EntityCard,
} from "../Components";
import Switch from "react-switch";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import { setShowChartModal } from "actions/modalActions";

export default function ResourceInputView({
    entitiesMap,
    currentEntity,
    eventData,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    handleDateSelection,
    updateAccount,
    handleChangeFlags,
    toggleInheritDate,
    getEntitySummary,
    getMonthsDifference,
    getMonthsArray,
    addUpdateDistribution,
    provideInheritedDate,
}) {
    const resourceCadences = ["Months"];

    const entityData = entitiesMap?.[currentEntity]?.data;

    const getDurationPanel = () => {
        const isFixed = entityData?.fixedOrAmortized === "fixed";
        return (
            <>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Switch
                            checked={entityData?.inheritedStartDate}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={(checked) =>
                                toggleInheritDate(checked, "inheritedStartDate")
                            }
                            onColor="#F8B46A"
                        />
                        <div className="InflationText">Inherit Start Date</div>
                    </ModalRowHalf>
                    {isFixed ? (
                        <ModalRowHalf>
                            <Switch
                                checked={entityData?.inheritedEndDate}
                                className="InflationToggle"
                                height={20}
                                width={40}
                                onChange={(checked) =>
                                    toggleInheritDate(
                                        checked,
                                        "inheritedEndDate"
                                    )
                                }
                                onColor="#F8B46A"
                            />
                            <div className="InflationText">
                                Inherit End Date
                            </div>
                        </ModalRowHalf>
                    ) : (
                        <ModalRowHalf>
                            <SelectDropDown
                                onChangeInput={handleOnChange}
                                value={entityData?.resourceCadence}
                                options={resourceCadences}
                                className="select-dropdown"
                                id="resourceCadence"
                                label="Resource Duration Frequency"
                                required={true}
                                hideNullOption={true}
                            />
                        </ModalRowHalf>
                    )}
                </ModalRow>
                <ModalRow twoInputs>
                    {entityData?.inheritedStartDate ||
                    entityData?.inheritedEndDate ? (
                        <ModalRowHalf>
                            <SelectEntities
                                onChangeInput={handleOnChange}
                                value={entityData?.selectedEvent ?? {}}
                                nodes={Object.values(entityData?.events ?? {})}
                                className="IncomeSelect"
                                id="entity"
                                label="Target Record"
                                required={true}
                            />
                        </ModalRowHalf>
                    ) : (
                        <ModalRowHalf></ModalRowHalf>
                    )}
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={
                                entityData?.inheritedStartDate
                                    ? provideInheritedDate("startDate")
                                    : entitiesMap?.[currentEntity]
                                          ?.startDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.startDate
                            }
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                            disabled={entityData?.inheritedStartDate}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        {isFixed ? (
                            <MuiCalendar
                                id="endDate"
                                value={
                                    entityData?.inheritedEndDate
                                        ? provideInheritedDate("endDate")
                                        : entitiesMap?.[currentEntity]
                                              ?.endDate === ""
                                        ? null
                                        : entitiesMap?.[currentEntity]?.endDate
                                }
                                onChangeInput={handleDateSelection}
                                label="End Date"
                                required={true}
                                disabled={entityData?.inheritedEndDate}
                            />
                        ) : (
                            <Rate
                                id="resourceDuration"
                                value={entityData?.resourceDuration}
                                onChangeInput={handleOnChange}
                                label="Resource Duration"
                                inputAdornmentOverride={
                                    entityData?.resourceCadence
                                }
                                disabled={
                                    !entitiesMap[currentEntity]?.startDate
                                }
                                required={true}
                            />
                        )}
                    </ModalRowHalf>
                </ModalRow>
            </>
        );
    };

    const getAmountPanel = () => {
        const isSet = entityData?.setOrDistributed === "set";
        return (
            <>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Value
                            id="value"
                            value={entityData?.value}
                            onChangeInput={handleOnChange}
                            label={
                                isSet
                                    ? "Resource Amount"
                                    : "Total Resources Required"
                            }
                            startAdornment=""
                            required={true}
                        />
                    </ModalRowHalf>
                    {!isSet && (
                        <ModalRowHalf>
                            <GenericButton
                                label={
                                    entityData?.distribution?.length
                                        ? "Edit Distribution"
                                        : "Create Distribution"
                                }
                                onClick={() => {
                                    setShowChartModal({
                                        show: true,
                                        numDataPoints: getMonthsDifference(),
                                        monthsStringArray: getMonthsArray(),
                                        addUpdateDistribution,
                                        yData: entityData?.nnDistribution
                                            ?.length
                                            ? entityData.nnDistribution
                                            : [],
                                    });
                                }}
                            />
                        </ModalRowHalf>
                    )}
                </ModalRow>
            </>
        );
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={resourceObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={resourceObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[resourceObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap?.[currentEntity]?.data
                                    ?.accountName ?? ""
                            }
                            topLevelFilters={{
                                "852b5a0b-2a32-4817-867c-cca92295141b": true,
                            }}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="fixed"
                        isSelected={entityData?.fixedOrAmortized === "fixed"}
                        onClick={(e) =>
                            handleChangeFlags(e, "fixedOrAmortized")
                        }
                        title="Fixed Duration"
                    />
                    <ToggledContainerHalf
                        id="amortized"
                        isSelected={
                            entityData?.fixedOrAmortized === "amortized"
                        }
                        onClick={(e) =>
                            handleChangeFlags(e, "fixedOrAmortized")
                        }
                        title="Amortized Duration"
                    />
                </ToggledContainer>
                {getDurationPanel()}
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="set"
                        isSelected={entityData?.setOrDistributed === "set"}
                        onClick={(e) =>
                            handleChangeFlags(e, "setOrDistributed")
                        }
                        title="Set Amount"
                    />
                    <ToggledContainerHalf
                        id="distributed"
                        isSelected={
                            entityData?.setOrDistributed === "distributed"
                        }
                        onClick={(e) =>
                            handleChangeFlags(e, "setOrDistributed")
                        }
                        title="Distributed Amount"
                        disabled={
                            entityData?.inheritedStartDate ||
                            entityData?.inheritedEndDate
                        }
                    />
                </ToggledContainer>
                {getAmountPanel()}
                {passedCheck && (
                    <ModalRow>
                        <EntitySummaryHighlightBlank
                            copy={getEntitySummary(
                                entitiesMap?.[currentEntity]
                            )}
                        />
                    </ModalRow>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}
