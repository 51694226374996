import { Component } from "react";
import { connect } from "react-redux";
import { appMapState } from "store/appMapStateDispatch";
import CreateScenarioModal from "../CreateScenarioModal";
import styles from "./ScenarioContainer.module.css";
import ScenarioNameSelect from "./ScenarioNameSelect";
import addSvg from "../../Assets/Add.svg";
import swal from "sweetalert";
import Scenario from "./Scenario";
import _ from "lodash";
import shareSvg from "../../Assets/share.svg";
import ScenarioInfo from "./info";
import ScenarioComment from "./comment";
import ScenarioFind from "./find";
import Modal from "@mui/material/Modal";
// import infoSvg from "../../Assets/scenarioIcons/info.svg";       <==== component commented out, might re-purpose later
// import commentSvg from "../../Assets/scenarioIcons/comment.svg"; <==== component commented out, might re-purpose later
// import findSvg from "../../Assets/scenarioIcons/find.svg";       <==== component commented out, might re-purpose later
import FindTabIcon from "../../Assets/scenarioIcons/search_tab_icon.svg";
import InfoTabIcon from "../../Assets/scenarioIcons/info_tab_icon.svg";
import CommentsTabIcon from "../../Assets/scenarioIcons/comments_tab_icon.svg";
import underlineSvg from "../../Assets/scenarioIcons/underline.svg";
import { Mixpanel } from "../../helpers/mixpanel";
import { EventsToast } from "../EventsToast";
import { EventsModal } from "../EventsModal";
import { ExpiredEventModal } from "Components/ExpiredEventModal";
import expiredNodeSvg from "../../Assets/_modalIcons/expiredNodeSvg.png";
import { AGENCY } from "../../helpers/constants";
import {
    getMaxScenarioCount,
    getPlanName,
    isActiveUserAdmin,
} from "../../helpers/userHelpers";
import { setShowDuplicateScenarioModal } from "actions/modalActions";
import { v4 as uuid } from "uuid";
import { createEntityCopies } from "actions/nodeEntityActions";
import { throwError } from "helpers/swalError";
import { editScenario } from "../../actions/scenario";
import { startObject } from "Components/Registry/Start";
// import { Tooltip } from '@mui/material';

class ScenariosContainer extends Component {
    _isMounted = false;
    state = {
        display: true,
        showSamples: true,
        edit: false,
        data: null,
        onHover: null,
        scenarioState: "find",
        toastData: null,
        deleteId: null,
        isPaused: false,
    };

    toggleDisplay = () => {
        const display = !this.state.display;
        this.setState({ display });
    };

    isHighlighted = (scenario) => {
        const { highlighted } = this.state;
        return highlighted &&
            highlighted.id === scenario.id &&
            highlighted.type === scenario.type
            ? "true"
            : "false";
    };

    onHandleMouseEnter = (data) => {
        this.setState({ onHover: data });
    };

    onHandleMouseOut = () => {
        this.setState({ onHover: null });
    };

    componentDidMount() {
        this._isMounted = true;

        if (this.props.showCreateScenario) {
            this.promptScenarioCreation();
        }
        this.setHighlighted(this.props.loadedScenario);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, _prevState) {
        if (
            this.props.loadedScenario &&
            this.props.loadedScenario !== prevProps.loadedScenario
        ) {
            this.setHighlighted(this.props.loadedScenario);
        }
        if (prevProps.userScenarios !== this.props.userScenarios) {
            const newCommentData =
                this.state.commentData &&
                this.props.userScenarios.filter((scenario) => {
                    return scenario.id === this.state.commentData.id;
                });
            if (newCommentData) {
                this.setState({
                    commentData: newCommentData[0],
                });
            }
        }

        if (prevProps.sharedScenario !== this.props.sharedScenario) {
            this.setState({});
        }
        if (prevProps.sampleScenarios !== this.props.sampleScenarios) {
            this.setState({});
        }

        if (
            prevProps.showCreateScenario !== this.props.showCreateScenario &&
            this.props.showCreateScenario
        ) {
            this.promptScenarioCreation();
        }
    }

    setHighlighted = (data, _onLoad = false) => {
        this.setState({ highlighted: data, data });
    };

    editScenario = (scenario) => {
        this.props.togglePromptScenarioEdit(true);
        scenario.type === "sample"
            ? this.props.setSampleScenarioEdit(true)
            : this.props.setSampleScenarioEdit(false);
        this.setState({
            // promptScenarioEdit: true,
            editDetails: scenario,
            edit: true,
            // sampleScenarioEdit: scenario.type === "sample" ? true : false,
        });

        this.props.setNodeGraphState("default");
    };

    submitEditScenario = (
        name,
        description,
        range,
        inflation,
        client,
        video_link,
        subtitle,
        custom_start_date,
        start_date_preference
    ) => {
        let newScenario = {
            ...this.state.editDetails,
            name,
            description,
            range,
            inflation,
            video_link,
            subtitle,
            custom_start_date,
            start_date_preference,
        };
        const scenarioId = newScenario.id;
        const previousClient = this.props.agencyClients.find((candidate) => {
            return (
                candidate.scenarios &&
                candidate.scenarios.includes(newScenario.id)
            );
        });

        //admin edit sample scenario
        if (this.props.sampleScenarioEdit) {
            // do nothing
        } else {
            this.props.editScenario(newScenario, (err, data) => {
                if (data) {
                    if (client) {
                        // add scenario to client
                        if (!client.scenarios) client.scenarios = [];
                        if (!client.scenarios.includes(scenarioId)) {
                            client.scenarios.push(scenarioId);
                            this.props.updateClient(client);
                        }
                    }
                    if (
                        previousClient &&
                        (!client || previousClient.id !== client.id)
                    ) {
                        // remove scenario from previous client, if one exists
                        previousClient.scenarios =
                            previousClient.scenarios.filter((scenario) => {
                                return scenario !== scenarioId;
                            });
                        this.props.updateClient(previousClient);
                    }

                    this.toggleClientListShouldUpdate(true);
                    this.props.getUserScenarios();
                    this.editSuccessToast();
                }
            });
        }
    };

    editSuccessToast = () => {
        const toastData = {
            type: "success",
            text: `You have successfully updated "${this.state.editDetails.name}"`,
            upgrade: false,
        };
        this.setState({ toastData }, () => {
            setTimeout(() => {
                this.setState({ toastData: null });
            }, 5000);
        });
    };

    loadScenario = (scenario) => {
        this.props.baselineDataManager.setIsFirstLoad(true);
        this.props.loadScenario(
            scenario,
            this.props.manager,
            this.props.baselineDataManager,
            null,
            this.props.eventLibrary
        );
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        Mixpanel.track("Scenario Views", {
            name: loggedInUser && loggedInUser.name,
            account: loggedInUser && loggedInUser.account,
            email: loggedInUser && loggedInUser.email,
            scenarioId: scenario.id,
            scenarioName: scenario.name,
        });
    };

    cancelToast = () => {
        this.setState({ toastData: null });
    };

    promptScenarioCreation = (sampleScenario) => {
        this.props.setNodeGraphState("default");
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const scenarioCount = this.props.userScenarios.length;

        const remainingCount =
            getMaxScenarioCount(loggedInUser && loggedInUser.plan) -
            scenarioCount;

        const isPassed = remainingCount > 0 || isActiveUserAdmin();

        if (loggedInUser && isPassed) {
            this.props.togglePromptScenarioCreation(true);
            this.props.setSampleScenarioCreation(sampleScenario);
            // this.setState({
            //     promptScenarioCreation: true,
            //     sampleScenarioCreation: sampleScenario,
            // });
        } else {
            if (!isPassed) {
                this.onHandleScenarioCountToast();
            } else {
                swal({
                    icon: "info",
                    text: "Please login or create an account to create a scenario",
                    buttons: {
                        cancel: "Cancel",
                        login: true,
                    },
                }).then((value) => {
                    if (value === "login") {
                        return this.props.openLogin();
                    } else {
                        return null;
                    }
                });
            }
        }
    };

    closeScenarioCreation = () => {
        this.props.togglePromptScenarioCreation(false);
        this.props.toggleSampleScenarioCreation(false);
        // this.setState({
        //     promptScenarioCreation: false,
        //     sampleScenarioCreation: false,
        // });
    };

    closeScenarioEdit = () => {
        this.props.togglePromptScenarioEdit(false);
        this.setState({
            // promptScenarioEdit: false,
            edit: false,
        });
    };

    onHandleScenarioCountToast = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const scenarioCount = this.props.userScenarios.length + 1;
        const planName = getPlanName(loggedInUser && loggedInUser.plan);
        const maxNumScenarios = getMaxScenarioCount(
            loggedInUser && loggedInUser.plan
        );
        const scenarioRemainingCount = maxNumScenarios - scenarioCount;
        const value = scenarioRemainingCount <= 0 ? 0 : scenarioRemainingCount;

        if (maxNumScenarios !== Infinity) {
            const toastData = {
                type: "warnings",
                text: `You have ${value} Scenarios left in your ${planName}`,
                upgrade: value ? false : true,
            };
            this.setState({ toastData }, () => {
                setTimeout(() => {
                    this.setState({ toastData: null });
                }, 7000);
            });
        }
    };

    createScenario = (
        name,
        description,
        range,
        inflation,
        client,
        explainerVideoLink,
        subtitle,
        custom_start_date,
        start_date_preference,
        dependency_map
    ) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        Mixpanel.track("Create Scenario", {
            name: loggedInUser && loggedInUser.name,
            account: loggedInUser && loggedInUser.account,
            email: loggedInUser && loggedInUser.email,
            scenarioName: name,
            subtitle: subtitle,
            description: description,
        });
        const { manager } = this.props;
        manager.addStart();
        let startNode;

        if (client) {
            startNode = manager.getRootNode();
            startNode.name = client.clientdata.name;
        }

        const scenarioData = {
            ...manager.exportData(),
            canvasCardIds: [],
        };

        const data = JSON.stringify(scenarioData);
        const scenario = {
            name,
            description,
            data,
            range,
            inflation,
            baselineid: client?.baseline ?? null,
            explainerVideoLink,
            subtitle,
            custom_start_date,
            start_date_preference,
            dependency_map,
        };
        const normalScenario = {
            name,
            description,
            data: scenarioData,
            range,
            inflation,
            explainerVideoLink,
            subtitle,
            custom_start_date,
            start_date_preference,
            dependency_map,
        };

        //create sample scenario (for jon only)
        if (this.props.sampleScenarioCreation) {
            this.props.createSampleScenario(scenario, () => {
                this.props.getTemplates().then(() => {
                    this.toggleClientListShouldUpdate(true);
                    this.props.toggleSampleScenarioCreation(false);
                    // this.setState({ sampleScenarioCreation: false });
                });
            });
        } else {
            this.props.createScenario(scenario, range, (data) => {
                if (data) {
                    if (client) {
                        if (!client.scenarios) client.scenarios = [];
                        client.scenarios.push(data.data.id);
                        this.props.updateClient(client);
                    }
                    this.props.getUserScenarios().then(() => {
                        this.toggleClientListShouldUpdate(true);
                    });
                }
            });
        }

        this.props.loadScenario(
            normalScenario,
            manager,
            this.props.baselineDataManager
        );
        this.onHandleScenarioCountToast();
    };

    copyScenario = (selectedScenario) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const scenarioCount = this.props.userScenarios.length;
        const remainingCount =
            getMaxScenarioCount(loggedInUser && loggedInUser.plan) -
            scenarioCount;

        const isPassed = remainingCount > 0 || isActiveUserAdmin();
        const { manager, activeAgencyClients } = this.props;

        const client = activeAgencyClients.find((client) =>
            client.scenarios.includes(selectedScenario.id)
        );

        const addScenario = (newScenario, linked = false) => {
            const linkedEventsDataArray = newScenario?.data?.nodes?.map(
                (node) => {
                    const newEvent = { ...node };

                    if (newEvent?.type !== startObject?.constant()) {
                        newEvent.linked = true;
                    }
                    return newEvent;
                }
            );

            if (linked) {
                const oldScenarioWithLinkedEvents = {
                    ...newScenario,
                    data: {
                        ...newScenario.data,
                        nodes: linkedEventsDataArray,
                    },
                };

                this.props.editScenario(oldScenarioWithLinkedEvents, () => {
                    return;
                });
            }

            const scenario = {
                name: newScenario.name + " - COPY",
                description: newScenario.description,
                data: JSON.stringify(newScenario.data),
                range: newScenario.range,
                inflation: newScenario.inflation,
                baselineid: newScenario.baselineid,
                start_date_preference: newScenario.start_date_preference,
                custom_start_date: newScenario.custom_start_date,
                dependency_map: newScenario.dependency_map ?? {},
            };

            const normalScenario = {
                ...scenario,
                data: linked
                    ? {
                          ...newScenario.data,
                          nodes: linkedEventsDataArray,
                      }
                    : newScenario.data,
            };

            this.props.createScenario(scenario, scenario.range, (data) => {
                if (data) {
                    if (client) {
                        if (!client.scenarios) client.scenarios = [];
                        client.scenarios.push(data.data.id);
                        this.props.updateClient(client);
                    }
                    this.props.getUserScenarios().then(() => {
                        this.toggleClientListShouldUpdate(true);
                    });
                }
            });

            this.props.loadScenario(
                normalScenario,
                manager,
                this.props.baselineDataManager,
                null,
                this.props.eventLibrary
            );
            this.onHandleScenarioCountToast();
        };

        const duplicate = (action) => {
            if (!loggedInUser || !isPassed) {
                if (!isPassed) {
                    this.onHandleScenarioCountToast();
                } else {
                    swal({
                        icon: "info",
                        text: "Please login or create an account to copy a scenario",
                        confirmButtonColor: "#f8b46a",
                    });
                }
                return;
            }
            if (action === "duplicate") {
                const scenarioData = selectedScenario.data;
                const dependencyMap =
                    { ...selectedScenario.dependency_map } ?? {};
                const newEntityIdsMap = {};
                scenarioData.nodes.forEach((node) => {
                    node.entities.forEach((entity) => {
                        const newId = newEntityIdsMap[entity.id] ?? uuid();
                        newEntityIdsMap[entity.id] = newId;
                        entity.id = newId;
                    });
                });
                createEntityCopies(
                    Object.keys(newEntityIdsMap),
                    newEntityIdsMap,
                    dependencyMap
                )
                    .then((response) => {
                        if (
                            response.data.length ===
                            Object.keys(newEntityIdsMap).length
                        ) {
                            addScenario({
                                ...selectedScenario,
                                data: scenarioData,
                                dependency_map: dependencyMap,
                            });
                        } else {
                            throw new Error("Failed to copy all Records.");
                        }
                    })
                    .catch((error) => {
                        throwError(
                            "error",
                            "Something went wrong when copying your Records. Please try again later."
                        );
                        console.log(error);
                    });
            } else {
                addScenario(selectedScenario, true);
            }
        };

        setShowDuplicateScenarioModal({
            show: true,
            callback: duplicate,
        });
    };

    confirmDelete = () => {
        this.props
            .deleteScenario(
                this.state.deleteId,
                this.props.manager,
                this.props.sampleScenarios
            )
            .then(() => {
                if (this.props.agencyClients) {
                    this.props.agencyClients.forEach((client) => {
                        if (
                            client.scenarios &&
                            client.scenarios.includes(this.state.deleteId)
                        ) {
                            client.scenarios = client.scenarios.filter(
                                (scenario) => {
                                    return scenario !== this.state.deleteId;
                                }
                            );
                            this.props.updateClient(client);
                        }
                    });
                }
            });

        this.setState({ toastData: null, deleteId: null });
    };

    deleteScenario = (scenario) => {
        const { id, type } = scenario;
        const isAdmin = isActiveUserAdmin();
        if (type !== "sample") {
            const toastData = {
                type: "warnings",
                text: `Delete "${scenario.name}" Scenario?`,
                upgrade: false,
            };
            this.setState({
                toastData,
                data: this.props.sampleScenarios[0],
                deleteId: id,
            });
        } else {
            if (isAdmin) {
                this.deleteSampleScenario(scenario);
            } else {
                const toastData = {
                    type: "error",
                    text: `You can't delete a sample scenario`,
                    upgrade: false,
                };
                this.setState(
                    {
                        toastData,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({ toastData: null });
                        }, 7000);
                    }
                );
            }
        }
    };

    shareScenario = () => {
        const loggedInUser = localStorage.getItem("loggedInUser");
        const { loadedScenario } = this.props;

        if (
            loggedInUser &&
            loadedScenario &&
            loadedScenario.type !== "sample" &&
            loadedScenario.type !== "shared"
        ) {
            Mixpanel.track("Shared Scenario", {
                name: loggedInUser && loggedInUser.name,
                account: loggedInUser && loggedInUser.account,
                email: loggedInUser && loggedInUser.email,
                scenarioName: loadedScenario.name,
                scenarioId: loadedScenario.id,
            });
            this.props.shareScenario(loadedScenario.id);
        } else {
            let errorMsg;
            if (!loggedInUser) {
                errorMsg = "Please Log In or Sign Up to Share a Scenario";
            } else if (loadedScenario.type === "sample") {
                errorMsg = "Sample Scenarios Cannot be Shared";
            } else if (loadedScenario.type === "shared") {
                errorMsg = "You Can Only Share Your Own Scenarios";
            } else {
                errorMsg = "Please Load the Scenario you want to share";
            }

            const toastData = {
                type: "error",
                text: errorMsg,
                upgrade: false,
            };
            this.setState(
                {
                    toastData,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );
        }
    };

    onHandleScenarioState = (id) => {
        if (this.state.scenarioState === id) {
            this.setState({ scenarioState: "closed" });
            this.toggleScenario();
        } else if (this.state.scenarioState === "closed") {
            this.setState({ scenarioState: id });
            this.toggleScenario();
        } else {
            this.setState({ scenarioState: id });
        }
    };

    toggleScenario = () => {
        this.props.toggleScenarios();
    };

    commentScenario = () => {
        this.setState({ scenarioState: "comment" });
    };

    onHandleCloseExpiredNodeModal = () => {
        //call function that close modal
        this.props.closeExpiredNodeModal();
    };

    onHandleCloseOutdatedNodeModal = () => {
        //call function that close modal
        this.props.closeOutdatedNodeModal();
    };

    createScenarioComponent = (scenario, notificationCount, isHover) => {
        return (
            <Scenario
                key={scenario.id}
                data={scenario}
                highlighted={this.isHighlighted(scenario)}
                select={this.setHighlighted}
                editScenario={this.editScenario}
                loadScenario={this.loadScenario}
                deleteScenario={this.deleteScenario}
                manager={this.props.manager}
                copyScenario={this.copyScenario}
                sharedScenario={true}
                onMouseEnter={this.onHandleMouseEnter}
                onMouseLeave={this.onHandleMouseOut}
                isHover={isHover}
                commentScenario={this.commentScenario}
                notificationCount={notificationCount}
                setNodeGraphState={this.props.setNodeGraphState}
            />
        );
    };

    sortScenariosByClient = (
        allScenarios, // input
        miscScenarios, // modified
        clientScenarios, // modified
        loggedInUser // input
    ) => {
        const { agencyClients } = this.props;

        allScenarios.forEach((scenario) => {
            let isHover = false;
            if (this.state.onHover) {
                isHover = scenario.id === this.state.onHover.id;
            }

            let notificationCount = 0;

            if (loggedInUser) {
                scenario.comments &&
                    scenario.comments.notification &&
                    scenario.comments.notification.forEach((notif) => {
                        if (
                            notif.commenterAccount !== loggedInUser.account &&
                            !notif.seen
                        ) {
                            notificationCount += 1;
                        }
                    });
            }

            const client = agencyClients.find((cl) => {
                return cl.scenarios && cl.scenarios.includes(scenario.id);
            });
            if (client) {
                // if the scenario owner is one of this user's clients:
                const name = client.clientdata.name;
                if (!clientScenarios[name]) {
                    clientScenarios[name] = [];
                }

                clientScenarios[name].push(
                    this.createScenarioComponent(
                        scenario,
                        notificationCount,
                        isHover
                    )
                );
            } else {
                miscScenarios.push(
                    this.createScenarioComponent(
                        scenario,
                        notificationCount,
                        isHover
                    )
                );
            }
        });
    };

    addScenarioToClient = (client, scenarioId) => {
        if (!client.scenarios) client.scenarios = [];
        if (client.scenarios.includes(scenarioId)) {
            return;
        }
        client.scenarios.push(scenarioId);
        this.props.updateClient(client);
    };

    sortSharedScenariosByClient = (
        allScenarios, // input
        miscScenarios, // modified
        clientScenarios, // modified
        loggedInUser // input
    ) => {
        const { agencyClients } = this.props;
        allScenarios.forEach((scenario) => {
            const { isHover, notificationCount } =
                this.getScenarioComponentInfo(scenario, loggedInUser);

            // cross-reference account field of shared scenario with account field of clients
            const client = agencyClients.find((cl) => {
                return Boolean(cl.account) && cl.account === scenario.account;
            });
            if (client) {
                // if the scenario owner is one of this user's clients:
                const name = client.clientdata.name;
                if (!clientScenarios[name]) {
                    clientScenarios[name] = [];
                }
                clientScenarios[name].push(
                    this.createScenarioComponent(
                        scenario,
                        notificationCount,
                        isHover
                    )
                );
            } else {
                miscScenarios.push(
                    this.createScenarioComponent(
                        scenario,
                        notificationCount,
                        isHover
                    )
                );
            }
        });
    };

    getScenarioComponentInfo = (scenario, loggedInUser) => {
        let isHover = false;
        if (this.state.onHover) {
            isHover = scenario.id === this.state.onHover.id;
        }

        let notificationCount = 0;

        if (loggedInUser) {
            scenario.comments &&
                scenario.comments.notification &&
                scenario.comments.notification.forEach((notif) => {
                    if (
                        notif.commenterAccount !== loggedInUser.account &&
                        !notif.seen
                    ) {
                        notificationCount += 1;
                    }
                });
        }
        return { isHover, notificationCount };
    };

    // use to control when ScenarioFind rebuilds its list from scratch,
    // since doing so is expensive, and not needed on every update/re-render
    toggleClientListShouldUpdate = (newValue) => {
        this.setState({ clientListShouldUpdate: newValue });
    };

    render() {
        const userScenariosIsEmpty = _.isEmpty(this.props.userScenarios);
        const sampleScenariosIsEmpty = _.isEmpty(this.props.sampleScenarios);
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const { showScenarios, expiredNodes, outdatedNodes } = this.props;
        const { scenarioState, deleteId, clientListShouldUpdate } = this.state;
        const userRole = localStorage.getItem("userRole");

        const sampleScenarios = [],
            userScenarios = [],
            archivedScenarios = [],
            clientScenarios = {};
        const isAgency = userRole === AGENCY;
        const tutorialScenarios = [];

        if (!sampleScenariosIsEmpty) {
            this.props.sampleScenarios.forEach((sample) => {
                let isHover = false;
                if (this.state.onHover) {
                    isHover = sample.id === this.state.onHover.id;
                }
                const notificationCount = 0;
                if (sample.is_tutorial) {
                    tutorialScenarios.push(
                        this.createScenarioComponent(
                            sample,
                            notificationCount,
                            isHover
                        )
                    );
                } else {
                    sampleScenarios.push(
                        this.createScenarioComponent(
                            sample,
                            notificationCount,
                            isHover
                        )
                    );
                }
            });
        } else {
            sampleScenarios.push(
                this.props.sampleScenariosLoading ? (
                    <div
                        className={styles.PromptUserScenarios}
                        key="LoadingSampleScenarios"
                    >
                        Loading sample scenarios...
                    </div>
                ) : (
                    <div
                        className={styles.PromptUserScenarios}
                        key="PromptSampleScenarios"
                    >
                        Failed to load sample scenarios
                    </div>
                )
            );
        }

        if (!userScenariosIsEmpty && isAgency) {
            this.sortScenariosByClient(
                this.props.userScenarios,
                userScenarios,
                clientScenarios,
                loggedInUser
            );
        } else if (!userScenariosIsEmpty && !isAgency) {
            this.props.userScenarios.forEach((scenario) => {
                const { isHover, notificationCount } =
                    this.getScenarioComponentInfo(scenario, loggedInUser);
                userScenarios.push(
                    this.createScenarioComponent(
                        scenario,
                        notificationCount,
                        isHover
                    )
                );
            });
        } else {
            userScenarios.push(
                this.props.userScenariosLoading ? (
                    <div
                        className={styles.PromptUserScenarios}
                        key="LoadingUserScenarios"
                    >
                        Loading user scenarios...
                    </div>
                ) : (
                    <div
                        className={styles.PromptUserScenarios}
                        key="PromptUserScenarios"
                    >
                        Create a user scenario by clicking the (+ New Scenario)
                        button above
                    </div>
                )
            );
        }

        const sharedScenario = [];
        const clientSharedScenarios = {};

        if (this.props.sharedScenario) {
            this.sortSharedScenariosByClient(
                this.props.sharedScenario,
                sharedScenario,
                clientSharedScenarios,
                loggedInUser
            );
        }
        this.props.archivedScenarios.forEach((scenario) => {
            const { isHover, notificationCount } =
                this.getScenarioComponentInfo(scenario, loggedInUser);
            archivedScenarios.push(
                this.createScenarioComponent(
                    scenario,
                    notificationCount,
                    isHover
                )
            );
        });

        const tabs = [
            {
                name: "Find",
                id: "find",
            },
            {
                name: "Info",
                id: "info",
            },
            {
                name: "Comment",
                id: "comment",
            },
        ];

        /* ***************************************************************** */
        /* THE CODE BELOW DECLARES THE FLOATING BUTTONS - MAY REPURPOSE THEM */
        /* ***************************************************************** */

        // const tabButtons = [
        //     {
        //         id: "find",
        //         icon: findSvg,
        //     },
        //     {
        //         id: "info",
        //         icon: infoSvg,
        //     },
        //     {
        //         id: "comment",
        //         icon: commentSvg,
        //     },
        // ];

        let showDisplay;
        switch (this.state.scenarioState) {
            case "find":
                // DELETING ARCHIVED USERS FROM clientScenarios SO THAT
                // THEY ARE NOT ACCESSIBLE FROM THE SCENARIO CANVAS
                // IN <ScenarioFind/> COMPONENT

                const archivedClientsByName =
                    this.props.archivedAgencyClients.map((client) => {
                        return client.clientdata.name;
                    });

                for (let i = 0; i < archivedClientsByName.length; i++) {
                    delete clientScenarios[archivedClientsByName[i]];
                }

                showDisplay = (
                    <ScenarioFind
                        sample={sampleScenarios}
                        tutorial={tutorialScenarios}
                        scenario={userScenarios}
                        userScenarios={this.props.userScenarios}
                        sharedScenario={this.props.sharedScenario}
                        shared={sharedScenario}
                        archivedScenarios={archivedScenarios}
                        clientSharedScenarios={clientSharedScenarios}
                        clientScenarios={clientScenarios}
                        loadedScenario={this.props.loadedScenario}
                        agencyClients={this.props.agencyClients}
                        activeAgencyClients={this.props.activeAgencyClients}
                        userRole={userRole}
                        clientListShouldUpdate={clientListShouldUpdate}
                        toggleClientListShouldUpdate={
                            this.toggleClientListShouldUpdate
                        }
                    />
                );
                break;
            case "info":
                showDisplay = (
                    <ScenarioInfo
                        data={this.props.loadedScenario}
                        editScenario={this.editScenario}
                        deleteScenario={this.deleteScenario}
                    />
                );
                break;
            case "comment":
                showDisplay = (
                    <ScenarioComment
                        data={this.props.loadedScenario}
                        manager={this.props.manager}
                        fetchSharedScenarios={this.props.fetchSharedScenarios}
                        loadScenario={this.props.loadScenario}
                        userScenarios={this.props.userScenarios}
                        sharedScenario={this.props.sharedScenario}
                        getUserScenarios={this.props.getUserScenarios}
                        editScenarioComments={this.props.editScenarioComments}
                    />
                );
                break;
            default:
        }

        return (
            <div className={styles.root}>
                {showScenarios && this.props.sampleScenarios && (
                    <div className={styles.scenarioData}>
                        <div className={styles.upperHalf}>
                            <div
                                className={styles.CreateNewScenario}
                                onClick={() =>
                                    this.promptScenarioCreation(false)
                                }
                            >
                                <img
                                    alt="alt"
                                    className={styles.CreateNewScenarioPlus}
                                    src={addSvg}
                                />

                                <div className={styles.CreateNewScenarioName}>
                                    New Scenario
                                </div>
                            </div>

                            <div
                                className={styles.shareScenarioContainer}
                                onClick={this.shareScenario}
                            >
                                <img
                                    alt="alt"
                                    className={styles.CreateNewScenarioPlus}
                                    src={shareSvg}
                                />

                                <div className={styles.shareName}>
                                    Share Scenario
                                </div>
                            </div>
                            <div className={styles.tabContainer}>
                                {tabs.map((tab, i) => {
                                    const isActive = scenarioState === tab.id;
                                    return (
                                        <div
                                            key={i}
                                            className={
                                                scenarioState === tab.id
                                                    ? styles.tabActive
                                                    : styles.tab
                                            }
                                            onClick={() =>
                                                this.onHandleScenarioState(
                                                    tab.id
                                                )
                                            }
                                        >
                                            {tab.name}
                                            {isActive && (
                                                <img
                                                    alt="alt"
                                                    src={underlineSvg}
                                                    className={
                                                        styles.underlineSvg
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* )} */}
                        {/* {showScenarios && this.props.sampleScenarios && ( */}
                        <div className={styles.bottomHalf}>{showDisplay}</div>
                    </div>
                )}
                <div className={styles.toggleContainer}>
                    <div>
                        <div style={{ display: "flex" }}>
                            {this.props.loadedScenario && (
                                <ScenarioNameSelect
                                    loadedScenario={this.props.loadedScenario}
                                    rightDisplayContent={
                                        this.props.rightDisplayContent
                                    }
                                />
                            )}
                        </div>
                        {/* ***************************************************************** */}
                        {/* THIS CODE BELOW RENDERS THE FLOATING BUTTONS - MAY REPURPOSE THEM */}
                        {/* ***************************************************************** */}

                        {/* <div className={styles.floatButtonsContainer}>
                            {tabButtons.map((tabButton, i) => {
                                return (
                                    <div
                                        key={i}
                                        onClick={() =>
                                            this.onHandleScenarioState(
                                                tabButton.id
                                            )
                                        }
                                        className={
                                            scenarioState === tabButton.id
                                                ? styles.buttonBackgroundActive
                                                : styles.buttonBackground
                                        }
                                    >
                                        <img
                                            alt="alt"
                                            src={tabButton.icon}
                                            className={styles.floatButton}
                                        />
                                    </div>
                                );
                            })}
                        </div> */}
                        <div className={styles.TabControlsContainer}>
                            <div
                                className={`${
                                    scenarioState === "find"
                                        ? styles.TabIconWrapperActive
                                        : styles.TabIconWrapper
                                } UserGuiding-ScenarioFinderTab`}
                                title="Scenario Finder"
                                onClick={() => {
                                    this.onHandleScenarioState("find");
                                }}
                            >
                                <img
                                    className={styles.TabIcon}
                                    src={FindTabIcon}
                                    alt="scenario finder tab"
                                />
                            </div>
                            <div
                                className={`${
                                    scenarioState === "info"
                                        ? styles.TabIconWrapperActive
                                        : styles.TabIconWrapper
                                } UserGuiding-ScenarioInfoTab`}
                                title="Info"
                                onClick={() => {
                                    this.onHandleScenarioState("info");
                                }}
                            >
                                <img
                                    className={styles.TabIcon}
                                    src={InfoTabIcon}
                                    alt="info tab"
                                />
                            </div>
                            <div
                                className={`${
                                    scenarioState === "comment"
                                        ? styles.TabIconWrapperActive
                                        : styles.TabIconWrapper
                                } UserGuiding-ScenarioCommentsTab`}
                                title="Comments"
                                onClick={() => {
                                    this.onHandleScenarioState("comment");
                                }}
                            >
                                <img
                                    className={styles.TabIcon}
                                    src={CommentsTabIcon}
                                    alt="comments tab"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={this.props.promptScenarioCreation}
                    onClose={this.closeScenarioCreation}
                >
                    <div>
                        <CreateScenarioModal
                            title="Create Scenario"
                            close={this.closeScenarioCreation}
                            submit={this.createScenario}
                            loadScenario={this.props.loadScenario}
                            manager={this.props.manager}
                            onboardingScenario={this.props.onboardingScenario}
                            getUserScenarios={this.props.getUserScenarios}
                            createScenario={this.props.createScenario}
                            showCreateScenario={this.props.showCreateScenario}
                            getBaseline={this.props.getBaseline}
                            baseline={this.props.baseline}
                            getAdminValues={this.props.getAdminValues}
                            baselineManager={this.props.baselineManager}
                            baselineDataManager={this.props.baselineDataManager}
                            agencyClients={this.props.agencyClients}
                            userScenarios={this.props.userScenarios}
                            scenarioTemplates={this.props.scenarioTemplates}
                            addScenarioToClient={this.addScenarioToClient}
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.props.promptScenarioEdit}
                    onClose={this.closeScenarioEdit}
                >
                    <div>
                        <CreateScenarioModal
                            title="Edit Scenario"
                            close={this.closeScenarioEdit}
                            submit={this.submitEditScenario}
                            currentDetails={this.state.editDetails}
                            edit={this.state.edit}
                            loadScenario={this.props.loadScenario}
                            manager={this.props.manager}
                            onboardingScenario={this.props.onboardingScenario}
                            getUserScenarios={this.props.getUserScenarios}
                            createScenario={this.props.createScenario}
                            showCreateScenario={this.props.showCreateScenario}
                            getBaseline={this.props.getBaseline}
                            baseline={this.props.baseline}
                            getAdminValues={this.props.getAdminValues}
                            baselineManager={this.props.baselineManager}
                            baselineDataManager={this.props.baselineDataManager}
                            agencyClients={this.props.agencyClients}
                            userScenarios={this.props.userScenarios}
                            scenarioTemplates={this.props.scenarioTemplates}
                        />
                    </div>
                </Modal>
                {this.state.toastData && (
                    <EventsToast
                        data={this.state.toastData}
                        close={this.cancelToast}
                        history={this.props.history}
                    >
                        {deleteId && (
                            <div className="promptButtonContainer">
                                <div
                                    onClick={this.cancelToast}
                                    className="deletePromptButton"
                                >
                                    Cancel
                                </div>
                                <div
                                    onClick={this.confirmDelete}
                                    className="deletePromptButton"
                                >
                                    Yes
                                </div>
                            </div>
                        )}
                    </EventsToast>
                )}
                {!this.state.toastData && this.props.showExpiredModal && (
                    <ExpiredEventModal
                        onClose={this.onHandleCloseExpiredNodeModal}
                        expiredNodes={expiredNodes}
                        expiredNodeSvg={expiredNodeSvg}
                    />
                )}
                {!this.state.toastData && this.props.showOutdatedModal && (
                    <EventsModal
                        onHandleClose={this.onHandleCloseOutdatedNodeModal}
                        confirm={true}
                    >
                        <div className="headerText">
                            <span>
                                You have {outdatedNodes.length}{" "}
                                {outdatedNodes.length > 1 ? "Events" : "Event"}{" "}
                                outdated in this scenario
                            </span>
                        </div>
                        <img
                            alt="alt"
                            src={expiredNodeSvg}
                            className="expiredNodeSvg"
                        />
                        <div className="subHeaderText">
                            This Event has recently been updated. Please click
                            the “Edit” button refresh the state of the node to
                            compile, or re-add the node into the scenario.
                        </div>
                        <div className="promptButtonContainer">
                            <div
                                onClick={this.onHandleCloseOutdatedNodeModal}
                                className="buttonOrange"
                            >
                                Got it
                            </div>
                        </div>
                    </EventsModal>
                )}
            </div>
        );
    }
}

const mapStateToProps = appMapState((state) => {
    const zoomedThreadId = state?.threadsList?.zoomedThreadId;
    const zoomedThreadName =
        state.calculatedThreads[zoomedThreadId]?.name || "";

    return {
        zoomedThreadId,
        zoomedThreadName,
    };
});

const mapDispatchToProps = {
    editScenario,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosContainer);
